const getReturnFormatedDate = (da) => {

    // Create a date object from a date string
    let date = new Date(da);
    // var today = new Date();
    const tomorrow = new Date();
    tomorrow.setDate(date.getDate() + 1);
    date = tomorrow
    // Get year, month, and day part from the date
    const year = date.toLocaleString("default", { year: "numeric" });
    const month = date.toLocaleString("default", { month: "2-digit" });
    const day = date.toLocaleString("default", { day: "2-digit" });

    return `${year}-${month}-${day}`
}

export default getReturnFormatedDate