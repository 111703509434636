import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import getDate from "../../../utils/getDate"
import flighlPhoto from "../../Photos/flight.svg"
import hotelPhoto from "../../Photos/hotel.svg"
import turePhoto from "../../Photos/ture.svg"
import { changeSearchType } from "../../../Redux/SearchType_slice"
import getReturnFormatedDate from '../../../utils/getFormatedDate';
import MultiCityFlightItem from './MultiCityFlightItem';
import SingleCityFlightItem from './SingleCityFlightItem';
import HotelSeachComponent from "./HotelSeachComponent"
import TravelSearchComponent from "../Search/TravelSearchComponent"
import { AiOutlineClose } from 'react-icons/ai';
import { MdOutlineModeEditOutline } from 'react-icons/md';

function SearchComponent({ Home }) {
    const navigation = useNavigate()
    const dispatch = useDispatch()
    const location = useLocation()
    const SearchType = useSelector((state) => state.SearchType.SearchType);
    const [travelType, setTravelType] = useState("one-way");
    const [searchOption, setSearchOption] = useState("");
    const [clickedSearchOption, setClickedSearchOption] = useState(false);
    const [clickedDateOption, setClickedDateOption] = useState(false);
    const [clickedClassOption, setClickedClassOption] = useState(false);
    const [totalTraveler, setTotalTraveler] = useState(1)
    const [ticketClass, setTicketClass] = useState("economy");
    const [toureData, setToureData] = useState({ name: "Cox's Bazar", id: 2 })
    const [showSerchComponent, setShowSearchComponent] = useState(true)

    const initialData = [
        {
            iata: "DAC",
            city: "Dhaka",
            name: "Hajrat Shahajalal Indernational Airport"
        },
        {
            iata: "CXB",
            city: "Coxs Bager",
            name: "Coxs Bager Airport"
        },
        {
            iata: "SHD",
            city: "Shaidpur Bangladesh",
            name: "Shaidpur"
        },
        {
            iata: "RAJ",
            city: "Rajshahi Bangladesh",
            name: "Rajshahi"
        },
        {
            iata: "BZL",
            city: "Borishal Bangladesh",
            name: "Borishal"
        },
    ]

    const hotelInitialData = [
        {
            iata: "DAC",
            city: "Villa Nueva, Argentina",
            name: "Great Sailfish Company",
            id: 1
        },
        {
            iata: "CXB",
            city: "Buenos Aires, Argentina",
            name: "El Ateneo Grand Splendid",
            id: 2
        },
        {
            iata: "SHD",
            city: "Avenida Corrientes, Argentina",
            name: "Convento de San Ramon",
            id: 3
        },
        {
            iata: "RAJ",
            city: "Comuna 1, Argentina",
            name: "Jewish Museum of Buenos Aires",
            id: 4
        },
        {
            iata: "BZL",
            city: "Avenida Corrientes, Argentina",
            name: "Multiteatro Buenos Aires",
            id: 5
        },
    ]

    const [travelers, setTravelers] = useState({
        adults: 1,
        children: 0,
        infant: 0
    })

    const traveleLocations = [
        {
            name: "Saint Martin's Island",
            id: 1
        },
        {
            name: "Cox's Bazar",
            id: 2
        },
        {
            name: "Sajek",
            id: 3
        },
        {
            name: "Tanguar Haor",
            id: 4
        },
        {
            name: "Maldives",
            id: 5
        },
        {
            name: "Rangamati",
            id: 6
        },
        {
            name: "Nepal",
            id: 7
        }
    ]

    const [hotelSearchData, setHotelSearchData] = useState({
        flightFrom: {
            iata: "DAC",
            city: "Villa Nueva, Argentina",
            name: "Great Sailfish Company",
            id: 1
        },
        checkinData: {

        },
        checkoutData: {

        },
        roomsData: [
            {
                guests: {
                    adults: 1,
                    childs: 0,
                },
                colaps: true
            }
        ]

    })

    const [singleCityData, setSingleCityData] = useState({
        flightFrom: {
            iata: "DAC",
            city: "Dhaka",
            name: "Hajrat Shahajalal Indernational Airport"
        },
        flightTo: {
            iata: "BZL",
            city: "Borishal Bangladesh",
            name: "Borishal"
        },
        depatureData: getDate(new Date()),
        returnData: getDate(new Date().setDate(new Date().getDate() + 1))
    })


    const [multicityData, setMulticityData] = useState([
        {
            flightFrom: {
                iata: "DAC",
                city: "Dhaka",
                name: "Hajrat Shahajalal Indernational Airport"
            },
            flightTo: {
                iata: "BZL",
                city: "Borishal Bangladesh",
                name: "Borishal"
            },
            depatureData: getDate(new Date()),
        }, {

        }
    ])


    useEffect(() => {

        if (location.pathname.split("/")[1] === "flight") {
            if (!location.search) {
                const today = new Date();
                const tomorrow = new Date();
                tomorrow.setDate(today.getDate() + 1);
                setSingleCityData({ ...singleCityData, depatureData: getDate(new Date()), returnData: getDate(tomorrow) })
            } else {
                let search = location.search.split("?")[1].split("&")
                let searchType = search[0].split("=")[1]
                setTravelType(searchType)

                let travelersiner = {}
                travelersiner.adults = parseInt(search[1].split("=")[1])
                travelersiner.children = parseInt(search[2].split("=")[1])
                travelersiner.infant = parseInt(search[3].split("=")[1])
                setTravelers(travelersiner)
                setTicketClass(search[4].split("=")[1])

                if (searchType === "one-way") {
                    const flightFromData = initialData.find((data) => data.iata === search[5].split("=")[1].split(",")[0])
                    const flightToData = initialData.find((data) => data.iata === search[5].split("=")[1].split(",")[1])
                    let data = {
                        flightFrom: flightFromData,
                        flightTo: flightToData,
                        depatureData: getDate(new Date(search[5].split("=")[1].split(",")[2])),
                    }
                    setSingleCityData(data)
                } else if (searchType === "round-way") {
                    const flightFromData = initialData.find((data) => data.iata === search[5].split("=")[1].split(",")[0])
                    const flightToData = initialData.find((data) => data.iata === search[5].split("=")[1].split(",")[1])
                    let data = {
                        flightFrom: flightFromData,
                        flightTo: flightToData,
                        depatureData: getDate(new Date(search[5].split("=")[1].split(",")[2])),
                        returnData: getDate(new Date(search[5].split("=")[1].split(",")[5]))
                    }
                    setSingleCityData(data)
                } else if (searchType === "multi-city") {

                    const multicitySearchData = []
                    // array split into 3 item per object
                    const array = search[5].split("=")[1].split(",")
                    const chunkSize = 3;
                    for (let i = 0; i < array.length; i += chunkSize) {
                        const chunk = array.slice(i, i + chunkSize);
                        multicitySearchData.push(chunk)
                    }
                    const multicityData = []

                    multicitySearchData.forEach((city) => {
                        const flightFromData = initialData.find((data) => data.iata === city[0])
                        const flightToData = initialData.find((data) => data.iata === city[1])
                        let data = {
                            flightFrom: flightFromData,
                            flightTo: flightToData,
                            depatureData: getDate(new Date(city[2])),
                        }
                        multicityData.push(data)
                    })
                    setMulticityData(multicityData)
                }
            }
        } else if (location.pathname.split("/")[1] === "hotel") {

            let search = location.search.split("?")[1].split("&")

            const flightFromData = hotelInitialData.find((data) => data.id === parseInt(search[2].split("=")[1]))

            const data = {
                flightFrom: { ...flightFromData },
                checkinData: getDate(new Date(search[0].split("=")[1])),
                checkoutData: getDate(new Date(search[1].split("=")[1])),
                roomsData: []
            }
            const hotelSearchData = []
            const array = search[3].split("=")[1].split(",")
            const chunkSize = 3;
            for (let i = 0; i < array.length; i += chunkSize) {
                const chunk = array.slice(i, i + chunkSize);
                hotelSearchData.push(chunk)
            }

            for (let i = 0; i < hotelSearchData.length; i++) {
                const inerdata = {
                    guests: {
                        adults: parseInt(hotelSearchData[i][1]),
                        childs: parseInt(hotelSearchData[i][2]),
                    },
                    colaps: true
                }
                if (i === 0) {
                    inerdata.colaps = true
                } else {
                    inerdata.colaps = false
                }
                data.roomsData.push(inerdata)
            }
            setHotelSearchData(data)
        } else if (location.pathname.split("/")[1] === "tour") {
            const locationiner = traveleLocations.find((loc) => parseInt(loc.id) === parseInt(location.search.split("?")[1].split("&")[0].split("=")[1]))
            setToureData(locationiner)
        }

        if (location.pathname !== "/") {
            changeSearchTypeFunc(location.pathname.split("/")[1])
        }
    }, [location.search])


    const increaseMultiCityData = (index) => {
        if (multicityData.length < 5) {
            setMulticityData([...multicityData, {}])
        }
    }

    const decreseMulticityData = (index) => {
        if (multicityData.length > 2) {
            const newmulticityData = []
            for (let i = 0; i < multicityData.length; i++) {
                if (index === i) {
                    continue
                }
                newmulticityData.push(multicityData[i])
            }
            setMulticityData(newmulticityData)
        }
    }


    const toggleSearchOption = (option, e) => {
        const closeReturnFlight = document.getElementById("close-return-flight");
        if (e.target.className.includes("search-item") || e.target === closeReturnFlight) {
            setClickedSearchOption(false)
            setSearchOption("")
        } else {
            setSearchOption(option);
            setClickedSearchOption(true);
        }
    }
    const toggleClassOption = (e) => {
        if (e.target.id === "Close") {
            setClickedClassOption(false)
        } else {
            setClickedClassOption(true)
        }
    }
    document.addEventListener('mouseup', function (e) {
        if (clickedSearchOption) {
            let searchFields = document.getElementById("search-fields")
            if (!searchFields?.contains(e.target)) {
                setClickedSearchOption(false)
            }
        }
        if (clickedDateOption) {
            let calander = document.getElementById("calander")
            if (!calander?.contains(e.target)) {
                setClickedDateOption(false)
            }
        }
        if (clickedClassOption) {
            let bookingClass = document.getElementById("bookingClass")
            if (!bookingClass?.contains(e.target)) {
                setClickedClassOption(false)
            }
        }
    });

    const changeFlightData = (data, option) => {
        if (option === "from") {
            setSingleCityData({ ...singleCityData, flightFrom: data })
            // setFilghtFrom(data)
        } else {
            setSingleCityData({ ...singleCityData, flightTo: data })
            // setFlightTo(data)
        }
    }
    const changeDateOption = (data, option) => {
        if (option === "Depature") {
            setSingleCityData({ ...singleCityData, depatureData: data })
        } else {
            setSingleCityData({ ...singleCityData, returnData: data })
        }
        setClickedDateOption(false)
    }


    const returnDataSet = () => {
        const returnDate = getReturnFormatedDate(new Date(singleCityData.depatureData.date))
        changeDateOption(getDate(returnDate), "return")
    }
    const showCalander = () => {
        const calander = document.getElementById("calander")
        calander.showPicker()
        setClickedDateOption(true)
    }
    const retrnshowCalander = (e) => {
        const closeReturnFlight = document.getElementById("close-return-flight");
        if (e.target !== closeReturnFlight) {
            setTravelType("round-way")
            const calander = document.getElementById("returncalander")
            calander.showPicker()
            setClickedDateOption(true)
        }
        setClickedClassOption(false)
    }

    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }

    const searchFlight = () => {

        let redirectLink = `/${SearchType}/list?`;
        // let searchData = {}
        if (SearchType === "flight") {
            if (travelType === "one-way") {
                let newredirectLink = ""
                const date = formatDate(singleCityData.depatureData.date)
                newredirectLink = `type=${travelType}&adult=${travelers.adults}&child=${travelers.children}&infant=${travelers.infant}&class=${ticketClass}&trips=${singleCityData.flightFrom.iata},${singleCityData.flightTo.iata},${date}`
                redirectLink = redirectLink + newredirectLink
                // searchData = { ...singleCityData, travelType, travelers, ticketClass }

            } else if (travelType === "round-way") {

                let newredirectLink = ""
                const date = formatDate(singleCityData.depatureData.date)
                const returnDate = formatDate(singleCityData.returnData.date)
                newredirectLink = `type=${travelType}&adult=${travelers.adults}&child=${travelers.children}&infant=${travelers.infant}&class=${ticketClass}&trips=${singleCityData.flightFrom.iata},${singleCityData.flightTo.iata},${date},${singleCityData.flightTo.iata},${singleCityData.flightFrom.iata},${returnDate}`
                redirectLink = redirectLink + newredirectLink

                // searchData = { ...singleCityData, travelType, travelers, ticketClass }

            } else if (travelType === "multi-city") {

                if (multicityData.length > 1) {
                    let isEmpty = false

                    for (let city of multicityData) {
                        if (!city.flightFrom || !city.flightTo || !city.depatureData) {
                            isEmpty = true
                        } else if (!city.flightFrom.iata || !city.flightTo.iata || !city.depatureData.date) {
                            isEmpty = true
                        }
                    }

                    if (isEmpty) {
                        window.alert("fill all fields")
                        return
                    }
                }
                let newredirectLink = ""
                newredirectLink = `type=${travelType}&adult=${travelers.adults}&child=${travelers.children}&infant=${travelers.infant}&class=${ticketClass}&trips=`
                multicityData.forEach((city, index) => {
                    newredirectLink = newredirectLink + `${city.flightFrom.iata},${city.flightTo.iata},${formatDate(city.depatureData.date)}`
                    if (index < multicityData.length - 1) {
                        newredirectLink = newredirectLink + ","
                    }
                })
                redirectLink = redirectLink + newredirectLink
                // searchData = { ...multicityData, travelType, travelers, ticketClass }
            }

        } else if (SearchType === "hotel") {
            let newredirectLink = ""
            const checkindate = formatDate(hotelSearchData.checkinData.date)
            const checkoutdate = formatDate(hotelSearchData.checkoutData.date)
            newredirectLink = `checkin=${checkindate}&checkout=${checkoutdate}&location=${hotelSearchData.flightFrom.id}&rooms=`

            hotelSearchData.roomsData.forEach((hotel, index) => {
                newredirectLink = newredirectLink + `1,${hotel.guests.adults},${hotel.guests.childs}`
                if (index < hotelSearchData.roomsData.length - 1) {
                    newredirectLink = newredirectLink + ","
                }
            })
            redirectLink = redirectLink + newredirectLink
        } else if (SearchType === "tour") {
            let newredirectLink = ""
            newredirectLink = `location=${toureData.id}`
            redirectLink = redirectLink + newredirectLink

        }
        navigation(redirectLink)
    }

    useEffect(() => {
        let total = 0
        for (let traveler in travelers) {
            total = total + travelers[traveler]
        }
        setTotalTraveler(total)
    }, [travelers])


    useEffect(() => {
        if (!location.search) {
            if (singleCityData.depatureData?.date) {
                returnDataSet()
            }
        }
    }, [location.search])



    const changeSearchTypeFunc = (value) => {
        dispatch(changeSearchType(value))
    }

    const switchTravelData = () => {
        const from = { ...singleCityData.flightFrom }
        const to = { ...singleCityData.flightTo }
        setSingleCityData({ ...singleCityData, flightFrom: to, flightTo: from })
    }

    window.addEventListener("resize", (e) => {
        if (window.innerWidth > 768 && !showSerchComponent) {
            setShowSearchComponent(true)
        }
    })


    return (
        <div className={` w-full  shadow bg-white  ${Home ? " p-3 md:p-5 lg:p-7 rounded-2xl pb-10" : "py-2 px-3 lg:px-7 pb-5"} z-[100]`}>
            <div className={` relative px-3 lg:px-5 ${Home ? "w-full" : "xl:w-[1160px] mx-auto "}`}>
                {/* top section */}
                {
                    Home &&
                    <div className=' flex items-center justify-center px-5 md:px-10 lg:px-20  bg-white shadow rounded-md absolute -top-14 left-1/2 -translate-x-1/2 '>
                        <div onClick={() => changeSearchTypeFunc("flight")} className={` flex items-center gap-2  cursor-pointer py-5 px-5 ${SearchType === "flight" && "border-b-2 border-yellow-400"}`}>
                            <img src={flighlPhoto} alt="" className=' lg:w-6 w-4' />
                            <span className=' text-primary font-semibold lg:text-base md:text-sm text-[12px] '>Flight</span>
                        </div>
                        <div onClick={() => changeSearchTypeFunc("hotel")} className={` flex items-center gap-2  cursor-pointer py-5 px-5 ${SearchType === "hotel" && "border-b-2 border-yellow-400"}`}>
                            <img src={hotelPhoto} alt="" className=' lg:w-6 w-4' />
                            <span className=' text-primary font-semibold lg:text-base md:text-sm text-[12px] '>Hotel</span>
                        </div>
                        <div onClick={() => changeSearchTypeFunc("tour")} className={` flex items-center gap-2  cursor-pointer py-5 px-5 ${SearchType === "tour" && "border-b-2 border-yellow-400"}`}>
                            <img src={turePhoto} alt="" className=' lg:w-6 w-4' />
                            <span className=' text-primary font-semibold lg:text-base md:text-sm text-[12px] '>Tour</span>
                        </div>
                    </div>
                }
                {
                    !Home && showSerchComponent &&
                    <div className={`w-full flex items-center justify-end md:hidden`}>
                        <AiOutlineClose onClick={() => setShowSearchComponent(false)} className=' text-gray-500 cursor-pointer text-lg' />
                    </div>
                }

                {
                    SearchType === "hotel" &&
                    <HotelSeachComponent hotelSearchData={hotelSearchData} setHotelSearchData={setHotelSearchData} initialData={hotelInitialData} showCalander={showCalander} retrnshowCalander={retrnshowCalander} showSerchComponent={showSerchComponent} setShowSearchComponent={setShowSearchComponent} ticketClass={ticketClass} />
                }
                {
                    SearchType === "tour" &&
                    <TravelSearchComponent traveleLocations={traveleLocations} toureData={toureData} setToureData={setToureData} showSerchComponent={showSerchComponent} setShowSearchComponent={setShowSearchComponent} ticketClass={ticketClass} />
                }
                {
                    SearchType === "flight" &&
                    <div>
                        <div className={` relative ${showSerchComponent ? "block" : "hidden"}`}>
                            {/* flighttype section*/}
                            <div className={` ${Home ? "pt-5 pb-3" : "pt-0"} flex items-center flex-wrap lg:gap-5 gap-3`}>
                                <div className=' flex items-center gap-1'>
                                    <input type="radio" name="" id="one-way" value={travelType} onChange={(e) => setTravelType("one-way")} checked={travelType === "one-way"} />
                                    <label htmlFor="one-way" className={` font-bold lg:text-base md:text-sm text-[12px] cursor-pointer ${travelType === "one-way" ? "text-primary" : "text-gray-300"} `}>One Way</label>
                                </div>
                                <div className=' flex items-center gap-1'>
                                    <input type="radio" name="" id="round-way" value={travelType} onChange={(e) => { returnDataSet(); setTravelType("round-way") }} checked={travelType === "round-way"} />
                                    <label htmlFor="round-way" className={` font-bold lg:text-base md:text-sm text-[12px] cursor-pointer ${travelType === "round-way" ? "text-primary" : "text-gray-300"} `}>Round Way</label>
                                </div>
                                <div className=' flex items-center gap-1'>
                                    <input type="radio" name="" id="multi-city" value={travelType} onChange={(e) => setTravelType("multi-city")} checked={travelType === "multi-city"} />
                                    <label htmlFor="multi-city" className={` font-bold lg:text-base md:text-sm text-[12px] cursor-pointer ${travelType === "multi-city" ? "text-primary" : "text-gray-300"} `}>Multi City</label>
                                </div>
                            </div>
                            {/* search section */}
                            {
                                travelType !== "multi-city" ?
                                    <SingleCityFlightItem travelers={travelers} setTravelers={setTravelers} ticketClass={ticketClass} setTicketClass={setTicketClass} totalTraveler={totalTraveler} clickedClassOption={clickedClassOption} clickedSearchOption={clickedSearchOption} switchTravelData={switchTravelData} toggleSearchOption={toggleSearchOption} singleCityData={singleCityData} searchOption={searchOption} changeFlightData={changeFlightData} clickedDateOption={clickedDateOption} setClickedDateOption={setClickedDateOption} retrnshowCalander={retrnshowCalander} toggleClassOption={toggleClassOption} travelType={travelType} setTravelType={setTravelType} changeDateOption={changeDateOption} setSingleCityData={setSingleCityData} initialData={initialData} showCalander={showCalander} searchFlight={searchFlight} />
                                    :
                                    <div className=' flex flex-col gap-2'>
                                        {
                                            multicityData.map((single, index) => {
                                                return (
                                                    <MultiCityFlightItem item={single} multicityData={multicityData} setMulticityData={setMulticityData} key={index} index={index} travelers={travelers} setTravelers={setTravelers} ticketClass={ticketClass} setTicketClass={setTicketClass} totalTraveler={totalTraveler} decreseMulticityData={decreseMulticityData} increaseMultiCityData={increaseMultiCityData} initialData={initialData} searchFlight={searchFlight} />
                                                )
                                            })
                                        }
                                    </div>
                            }
                        </div>
                        {
                            !Home && !showSerchComponent && travelType !== "multi-city" &&
                            <div className=' w-full flex justify-between items-center md:hidden'>
                                <div>
                                    <p className=' text-primary font-semibold'>{singleCityData.flightFrom.iata} - {singleCityData.flightTo.iata}</p>
                                    <p className=' text-[12px] text-gray-500'>{singleCityData.depatureData.date} | {totalTraveler} Traveler | <span className=' capitalize'>{ticketClass}</span></p>
                                </div>
                                <div className=' flex items-center gap-1 bg-yellow-400 py-1 px-2  rounded cursor-pointer' onClick={() => setShowSearchComponent(true)}>
                                    <MdOutlineModeEditOutline className=' text-gray-800 text-sm' />
                                    <button className=' font-semibold'>Edit</button>
                                </div>
                            </div>
                        }
                        {
                            !Home && !showSerchComponent && travelType === "multi-city" &&
                            <div className=' w-full md:hidden'>

                                {
                                    multicityData.map((city) => {
                                        return (
                                            <div className='border-b pb-1 mb-2'>
                                                <p className=' text-primary font-semibold'>{city.flightFrom.iata} - {city.flightTo.iata}</p>
                                                <p className=' text-[12px] text-gray-500'>{city.depatureData.date}</p>
                                            </div>

                                        )
                                    })
                                }
                                <p className=' pb-2 text-[12px] text-gray-500'>{totalTraveler} Traveler | <span className=' capitalize'>{ticketClass}</span> </p>
                                <div className=' flex items-center gap-1 bg-yellow-400 py-1 px-2  rounded cursor-pointer' onClick={() => setShowSearchComponent(true)}>
                                    <MdOutlineModeEditOutline className=' text-gray-800 text-sm' />
                                    <button className=' font-semibold'>Edit</button>
                                </div>
                            </div>
                        }

                    </div>
                }
                <div className={`flex justify-center w-full ${Home ? "block" : "hidden"}`}>
                    <button onClick={searchFlight} className={`z-1 absolute -bottom-14 left-1/2 -translate-x-1/2 shadow py-1 md:py-[6px] lg:py-[10px] px-5 md:px-8 lg:px-12 bg-yellow-400 text-sm md:text-base lg:text-lg text-primary font-semibold rounded-md`}>{Home ? "Search" : "Modify"}</button>
                </div>
            </div>
        </div>
    )
}

export default SearchComponent