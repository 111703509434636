import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import logo from "../../Photos/logo.svg";
import flighlPhoto from "../../Photos/flight.svg";
import hotelPhoto from "../../Photos/hotel.svg";
import turePhoto from "../../Photos/ture.svg";
import { changeSearchType } from "../../../Redux/SearchType_slice";
import { useDispatch, useSelector } from "react-redux";

function Header() {
  const [isScrolled, setIsScrolled] = useState(false);
  const SearchType = useSelector((state) => state.SearchType.SearchType);
  const dispatch = useDispatch();
  const location = useLocation();

  window.addEventListener("scroll", (e) => {
    if (window.scrollY > 10) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  });

  const changeSearchTypeFunc = (value) => {
    dispatch(changeSearchType(value));
  };

  return (
    <div
      className={`w-full flex items-center justify-center ${
        isScrolled || location.pathname !== "/"
          ? " bg-white shadow shadow-gray-300"
          : "bg-transparent"
      } transition-all duration-200 ease-linear fixed top-0 left-0 z-[1000]`}
    >
      <div className=" w-full xl:w-[1160px] flex justify-between items-center px-3 lg:px-5">
        <div>
          <Link to="/">
            <img src={logo} alt="" className=" w-16 md:w-24 lg:w-32" />
          </Link>
        </div>
        <div
          className={` ${
            isScrolled || location.pathname !== "/" || location.pathname !== "/"
              ? " opacity-100"
              : " opacity-0"
          } flex items-center justify-centr transition-all duration-200 ease-linear`}
        >
          <Link to="/">
            <div
              onClick={() => changeSearchTypeFunc("flight")}
              className={`cursor-pointer flex flex-col items-center justify-center py-2 lg:py-5 px-3 lg:px-5 ${
                SearchType === "flight" &&
                location.pathname === "/" &&
                "border-b-2 border-yellow-400"
              }`}
            >
              <img src={flighlPhoto} alt="" className=" w-4 lg:w-6" />
              <span className=" text-primary text-[11px] lg:text-sm font-bold">
                Flight
              </span>
            </div>
          </Link>
          <Link to="/">
            <div
              onClick={() => changeSearchTypeFunc("hotel")}
              className={`cursor-pointer flex flex-col items-center justify-center py-2 lg:py-5 px-3 lg:px-5 ${
                SearchType === "hotel" &&
                location.pathname === "/" &&
                "border-b-2 border-yellow-400"
              }`}
            >
              <img src={hotelPhoto} alt="" className=" w-4 lg:w-6" />
              <span className=" text-primary text-[11px] lg:text-sm font-bold">
                Hotel
              </span>
            </div>
          </Link>
          <Link to="/">
            <div
              onClick={() => changeSearchTypeFunc("tour")}
              className={`cursor-pointer flex flex-col items-center justify-center py-2 lg:py-5 px-3 lg:px-5 ${
                SearchType === "tour" &&
                location.pathname === "/" &&
                "border-b-2 border-yellow-400"
              }`}
            >
              <img src={turePhoto} alt="" className=" w-4 lg:w-6" />
              <span className=" text-primary text-[11px] lg:text-sm font-bold">
                Tour
              </span>
            </div>
          </Link>
        </div>
        <div>
          <button className=" bg-primary shadow shadow-primary hover:shadow-lg hover:shadow-primary py-1 sm:py-2 px-2 text-[12px] lg:text-sm font-medium  sm:px-5 rounded text-white transition-all ease-in duration-200 ">
            Sign in
          </button>
        </div>
      </div>
    </div>
  );
}

export default Header;
