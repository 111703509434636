import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { AiOutlineClose, AiOutlineSearch } from 'react-icons/ai'
import FilterSelectItem from '../FlightFilters/FilterSelectItem'
import FilterTimeComponent from '../FlightFilters/FilterTimeComponent'
import TagsList from "./TagList"
import TourRangeComponent from './TourPriceRange'

export default function TourFilterComponent({ showFilterComponent, setShowFilterComponent }) {

    const [showFilter, setShowFilter] = useState(true)

    // resize 
    window.addEventListener("resize", (e) => {
        if (window.innerWidth >= 768) {
            if (!showFilter) {
                setShowFilter(true)
            }
        } else {
            setShowFilter(false)
        }
    })

    // initial load
    useEffect(() => {
        if (window.innerWidth >= 768) {
            setShowFilter(true)
        } else {
            setShowFilter(false)
        }
    }, [])

    useEffect(() => {
        if (showFilterComponent) {
            setShowFilter(true)
        }
    }, [showFilterComponent])
    console.log(showFilterComponent)

    const tags = [
        "Go Zayaan Exclusive", "Water Parks", "Family friendly", "Tickets & Passes", "Adrenaline & Extreme", "Boat Rental", "Bike Rentals", "Beach Activity", "Nightlife", "Adventure", "Vespa Rentals"
        , "Theme Parks", "Temple", "Weddings & Honeymoons", "Long Drive", "Outdoor Activities", "Attraction Tickets", "Fishing Tours", "New Years", "Hiking & Camping", "Eco Tours", "alf Day Tours",
        "Cruises, Sailing & Water Tours", "Entertainment Packages", "Marine drive", "Sightseeing", "Day Tour", "Private Day Trips", "Kid Friendly", "Road Trip", "Day Cruises"
    ]

    const [displayTags, setdisplayTags] = useState([])

    useEffect(() => {
        setdisplayTags([...tags].slice(0, 5))
    }, [])

    const [showtags, setShotags] = useState(false)

    const toggleShowtags = () => {

        if (!showtags) {
            setShotags(true)
            setdisplayTags([...tags])
        } else {
            setShotags(false)
            setdisplayTags([...tags].slice(0, 5))
        }
    }


    return (
        <div className={`${showFilter ? "fixed top-12 z-[10] md:z-0 left-0 md:static h-screen md:h-auto w-screen md:w-full" : "hidden"} `}>
            <div className=" w-full h-full bg-white shadow rounded-md">
                <div className=" py-3 px-5 flex justify-between">
                    <p className=" text-primary text-[12px] md:text-sm font-bold">Destination: 3 properties found</p>
                    <div className={`md:hidden`}>
                        <AiOutlineClose onClick={() => { setShowFilter(false); setShowFilterComponent(false) }} className=' text-gray-500 cursor-pointer text-lg' />
                    </div>
                </div>
                <div className=" md:mt-5">
                    <div className=" px-5">
                        <p className=" text-primary font-bold text-sm md:text-base">Filter By</p>
                    </div>
                    <hr className=" mt-3" />
                </div>
                <div className=" p-5">
                    <div>
                        <TourRangeComponent />
                    </div>
                    <hr className=" my-4" />
                    <div>
                        <p className=' font-medium text-primary text-sm'>Duration</p>
                        <div className='mt-2 text-gray-500'>
                            <FilterSelectItem title="Less than 6 hours" />
                        </div>
                        <div className='mt-2 text-gray-500'>
                            <FilterSelectItem title="6 - 12 hours" />
                        </div>
                        <div className='mt-2 text-gray-500'>
                            <FilterSelectItem title="12 - 24 hours" />
                        </div>
                        <div className='mt-2 text-gray-500'>
                            <FilterSelectItem title="24+ hours" />
                        </div>
                    </div>
                    <hr className=" my-4" />
                    <div className=''>
                        <p className=' text-primary font-bold mb-2'>Time</p>
                        <FilterTimeComponent />
                    </div>
                    <hr className=" my-4" />
                    <div>
                        <p className=' font-medium text-primary text-sm'>Tags</p>
                        <TagsList tags={displayTags} />
                        {
                            showtags ?
                                <p className=' text-primary font-semibold text-sm cursor-pointer text-right' onClick={toggleShowtags}>Less</p>
                                :
                                <p className=' text-primary font-semibold text-sm cursor-pointer text-right' onClick={toggleShowtags}>More</p>
                        }

                    </div>
                </div>
                <div className=' px-5 pb-5'>
                    <button className=' w-full bg-yellow-400 py-2 rounded-lg font-semibold text-primary'>Reset Filters</button>
                </div>
            </div>
        </div>
    )
}
