import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import getDate from '../../../utils/getDate';
import getReturnFormatedDate from '../../../utils/getFormatedDate';
import HotelSearchComponent from "../Search/HotelSearchField"
import CalenderComponent from './CalenderComponent';
import ReturnCalenderComponent from './ReturnCalenderCompponent';
import HotelBookingClass from "../Search/HotelBookingClass"
import { useLocation } from 'react-router-dom';
import { MdOutlineModeEditOutline } from 'react-icons/md';

export default function HotelSearchData({ hotelSearchData, setHotelSearchData, initialData, showCalander, retrnshowCalander, showSerchComponent, setShowSearchComponent }) {
    const [clickedSearchOption, setClickedSearchOption] = useState(false);
    const [searchOption, setSearchOption] = useState("");
    const [clickedDateOption, setClickedDateOption] = useState(false);
    const [clickedClassOption, setClickedClassOption] = useState(false);
    const [totalTraveler, setTotalTraveler] = useState(0)
    const [totalAdults, setTotalAdults] = useState(0)
    const [totalChilds, setTotalChilds] = useState(0)
    const location = useLocation()

    useEffect(() => {
        let total = 0
        let totalAudults = 0
        let totalchilds = 0

        for (let traveler of hotelSearchData.roomsData) {
            for (let tr in traveler.guests) {
                total = total + traveler.guests[tr]
            }
            totalAudults = totalAudults + traveler.guests.adults
            totalchilds = totalchilds + traveler.guests.childs
        }
        setTotalTraveler(total)
        setTotalAdults(totalAudults)
        setTotalChilds(totalchilds)
    }, [hotelSearchData])

    const changeFlightData = (data) => {
        setHotelSearchData({ ...hotelSearchData, flightFrom: data })
    }
    const toggleSearchOption = (option, e) => {
        const closeReturnFlight = document.getElementById("close-return-flight");
        if (e.target.className.includes("search-item") || e.target === closeReturnFlight) {
            setClickedSearchOption(false)
            setSearchOption("")
        } else {
            setSearchOption(option);
            setClickedSearchOption(true);
        }
    }
    const toggleDateOption = () => {
        setClickedDateOption(true);
    }
    // console.log({ clickedDateOption, searchOption })

    const changeDateOption = (data, option) => {
        if (option === "Depature") {
            setHotelSearchData({ ...hotelSearchData, checkinData: data })
        } else {
            setHotelSearchData({ ...hotelSearchData, checkoutData: data })
        }
        setClickedDateOption(false)
    }
    document.addEventListener('mouseup', function (e) {
        if (clickedSearchOption) {
            let searchFields = document.getElementById("search-fields")
            if (!searchFields?.contains(e.target)) {
                setClickedSearchOption(false)
                // setSearchOption("")
            }
        }
        if (clickedClassOption) {
            let bookingClass = document.getElementById("bookingClass")
            if (!bookingClass?.contains(e.target)) {
                setClickedClassOption(false)
                // setSearchOption("")
            }
        }
        if (clickedDateOption) {
            let calander = document.getElementById("calander")
            if (!calander?.contains(e.target)) {
                setClickedDateOption(false);
                // setSearchOption("")
            }
        }
    });

    const returnDataSet = () => {
        const returnDate = getReturnFormatedDate(new Date(hotelSearchData.checkinData.date))
        changeDateOption(getDate(returnDate), "return")
    }

    const toggleClassOption = (e) => {
        if (e.target.id === "Close") {
            setClickedClassOption(false)
        } else {
            setClickedClassOption(true)
        }
    }

    useEffect(() => {
        const today = new Date();
        const tomorrow = new Date();
        tomorrow.setDate(today.getDate() + 1);
        setHotelSearchData({ ...hotelSearchData, checkinData: getDate(new Date()), checkoutData: getDate(tomorrow) })
    }, [])

    useEffect(() => {
        if (hotelSearchData.checkinData?.date) {
            returnDataSet()
        }
    }, [hotelSearchData.checkinData])

    return (
        <>
            <div className={`${location.pathname === "/" ? "w-full pt-5" : " pt-2 grid grid-cols-12 gap-2"} ${showSerchComponent ? "block" : "hidden"}`}>
                <div className={`grid grid-cols-1 md:grid-cols-4 gap-2 relative ${location.pathname === "/" ? "" : " col-span-12 md:col-span-10"}`}>
                    <div className=' relative w-full h-full'>
                        <div onClick={(e) => { toggleSearchOption("from", e) }} className={` border w-full h-full rounded-xl py-1 px-2 text-left cursor-pointer ${searchOption === "from" && clickedSearchOption && " bg-gray-100"}`}>
                            <p className=" text-primary text-[10px] md:text-[12px]">CITY/HOTEL/RESORT/AREA</p>
                            <p className=' font-bold text-primary md:text-base text-sm'>{hotelSearchData.flightFrom.city}</p>
                            <p className='text-primary text-[10px] md:text-[12px]'>{hotelSearchData.flightFrom.name}</p>
                            {
                                clickedSearchOption && searchOption === "from" &&
                                <HotelSearchComponent initialData={initialData} changeFlightData={changeFlightData} />
                            }

                        </div>
                    </div>

                    <div onClick={(e) => { showCalander(); toggleSearchOption("depature", e); toggleDateOption() }} className={` text-left py-1 px-3 border rounded-lg relative cursor-pointer ${searchOption === "depature" && clickedDateOption && "bg-gray-100"}`}>
                        <p className=" text-primary text-[12px] md:text-sm">CHECK IN</p>
                        <p className=' font-bold text-primary md:text-base text-sm'>{hotelSearchData.checkinData.date}</p>
                        <p className=' text-primary text-[10px] md:text-[12px]'>{hotelSearchData.checkinData.day}</p>
                        <CalenderComponent option="Depature" setClickedDateOption={setClickedDateOption} changeDateOption={changeDateOption} />
                    </div>
                    <div onClick={(e) => { retrnshowCalander(e); toggleSearchOption("return", e); toggleDateOption() }} className={` border rounded-lg text-left px-3 py-1 relative cursor-pointer ${searchOption === "return" && clickedDateOption && "bg-gray-100"}`}>
                        <p className=" text-primary text-[12px] md:text-sm">CHECK OUT</p>
                        <p className=' font-bold text-primary md:text-base text-sm'>{hotelSearchData.checkoutData?.date}</p>
                        <p className=' text-primary text-[10px] md:text-[12px]'>{hotelSearchData.checkoutData?.day}</p>
                        <ReturnCalenderComponent option="return" setClickedDateOption={setClickedDateOption} changeDateOption={changeDateOption} depatureData={hotelSearchData.checkinData} />
                    </div>
                    <div onClick={(e) => { toggleClassOption(e); toggleSearchOption("traveler", e) }} className={` border rounded-lg px-3 py-1  text-left cursor-pointer relative ${searchOption === "traveler" && clickedClassOption && "bg-gray-100"}`}>
                        <p className="  text-primary text-[10px] md:text-[12px]">ROOMS AND GUESTS</p>
                        <p className=' text-primary text-[12px] md:text-sm'><span className=' font-semibold'>{hotelSearchData.roomsData.length}</span> Rooms, <span className='font-semibold'>{totalTraveler}</span> Guents  </p>
                        <p className=' text-[12px] text-primary'>{totalAdults} Adults, {totalChilds} Children</p>
                        {
                            clickedClassOption &&
                            <HotelBookingClass hotelSearchData={hotelSearchData} setHotelSearchData={setHotelSearchData} />
                        }
                    </div>
                </div>
                <div className={`${location.pathname === "/" ? "hidden" : "block col-span-12 md:col-span-2"}`}>
                    <button className={` w-full h-full shadow py-[10px] text-center bg-yellow-400 text-sm sm:text-base md:text-lg leading-5 text-primary font-bold rounded-md`}>
                        <p>Modify Search</p>
                    </button>
                </div>
            </div>
            {
                location.pathname !== "/" && !showSerchComponent &&
                <div className=' w-full flex justify-between items-center md:hidden'>
                    <div>
                        <p className=' text-primary text-sm font-semibold'>{hotelSearchData?.flightFrom?.city}</p>
                        <p className=' text-primary  font-semibold'>{hotelSearchData?.flightFrom?.name}</p>
                        <p className=' text-[12px] text-gray-500'>From <span className=' font-semibold'>{hotelSearchData.checkinData.date}</span> To <span className=' font-semibold'>{hotelSearchData.checkoutData.date}</span></p>
                    </div>
                    <div className=' flex items-center gap-1 bg-yellow-400 py-1 px-2  rounded cursor-pointer' onClick={() => setShowSearchComponent(true)}>
                        <MdOutlineModeEditOutline className=' text-gray-800 text-sm' />
                        <button className=' font-semibold'>Edit</button>
                    </div>
                </div>
            }
        </>
    )
}
