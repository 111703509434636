import React from 'react'
import { AiFillPlusCircle, AiOutlineMinus } from 'react-icons/ai'
import { BsPlusLg } from "react-icons/bs"

function BookingClass({ hotelSearchData, setHotelSearchData }) {
    const incrementTravelers = (option, index) => {
        if (option === "adults") {
            const newData = { ...hotelSearchData }
            newData.roomsData[index].guests.adults = hotelSearchData.roomsData[index].guests.adults + 1
            setHotelSearchData(newData)
        } else if (option === "childs") {
            const newData = { ...hotelSearchData }
            newData.roomsData[index].guests.childs = hotelSearchData.roomsData[index].guests.childs + 1
            setHotelSearchData(newData)
        }
    }

    const dicrimentTravelers = (option, index) => {
        if (option === "adults") {
            if (hotelSearchData.roomsData[index].guests.adults >= 1) {
                const newData = { ...hotelSearchData }
                newData.roomsData[index].guests.adults = hotelSearchData.roomsData[index].guests.adults - 1
                setHotelSearchData(newData)
            }
        } else if (option === "childs") {
            if (hotelSearchData.roomsData[index].guests.childs >= 1) {
                const newData = { ...hotelSearchData }
                newData.roomsData[index].guests.childs = hotelSearchData.roomsData[index].guests.childs - 1
                setHotelSearchData(newData)
            }
        }
    }

    const addArotherRoom = () => {
        if (hotelSearchData.roomsData.length < 5) {
            const newData = { ...hotelSearchData }
            for (let index in hotelSearchData.roomsData) {
                newData.roomsData[index].colaps = false
            }
            newData.roomsData.push({
                guests: {
                    adults: 0,
                    childs: 0,
                },
                colaps: true
            })
            setHotelSearchData(newData)
        }
    }

    const removeRoom = (index) => {
        const newData = { ...hotelSearchData }
        if (newData.roomsData[index].colaps) {
            setHotelSearchData(newData)
            newData.roomsData[index - 1].colaps = true
            newData.roomsData.splice(index, 1)
        } else {
            const newData = { ...hotelSearchData }
            newData.roomsData.splice(index, 1)
        }
        setHotelSearchData(newData)
    }

    return (
        <>
            <div className=' z-10 absolute top-full left-0  p-2 w-56 sm:w-72 bg-white shadow-lg rounded-md shadow-gray-600' id='bookingClass'>
                {
                    hotelSearchData.roomsData.map((room, index) => {
                        return (
                            <>
                                <div className=' py-2'>
                                    <div className=' pb-2  border-b border-gray-300 '>
                                        <p className='text-primary text-lg font-semibold'>Room {index + 1}</p>
                                        {
                                            room.colaps && hotelSearchData.roomsData.length > 1 &&
                                            <div className=' w-full flex justify-end'>
                                                <span className=' text-sm font-medium text-primary cursor-pointer' onClick={() => removeRoom(index)}>Remove</span>
                                            </div>
                                        }
                                        {
                                            !room.colaps &&
                                            <div className=' flex justify-between items-center'>
                                                <p className=' text-sm text-gray-400'>{room.guests.adults} Adults, {room.guests.childs} Children</p>
                                                <span className=' text-sm font-medium text-primary cursor-pointer' onClick={() => removeRoom(index)}>Remove</span>
                                            </div>
                                        }
                                    </div>
                                    {
                                        room.colaps &&
                                        <div>
                                            <div className=' flex items-center justify-between border-b border-gray-300 py-3'>
                                                <div>
                                                    <p className=' text-sm text-primary'>Adults</p>
                                                    <p className=' text-[12px] text-primary -mt-[2px]'>12 years and above</p>
                                                </div>
                                                <div className=' flex items-center '>
                                                    <div onClick={() => dicrimentTravelers("adults", index)} className='flex justify-center items-center h-5 w-5 rounded-full border border-primary mr-3'>
                                                        <AiOutlineMinus className=' text-primary text-[10px]' />
                                                    </div>
                                                    <span className=''>{room.guests.adults}</span>
                                                    <div onClick={() => incrementTravelers("adults", index)} className='flex justify-center items-center h-5 w-5 rounded-full border border-primary ml-3'>
                                                        <BsPlusLg className=' text-primary text-[10px]' />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className=' mb-1 border-b border-gray-300 py-3'>
                                                <div className=' flex items-center justify-between '>
                                                    <div>
                                                        <p className=' text-sm text-primary'>Child</p>
                                                        <p className=' text-[12px] text-primary -mt-[2px]'>12 years and above</p>
                                                    </div>
                                                    <div className=' flex items-center '>
                                                        <div onClick={() => dicrimentTravelers("childs", index)} className='flex justify-center items-center h-5 w-5 rounded-full border border-primary mr-3'>
                                                            <AiOutlineMinus className=' text-primary text-[10px]' />
                                                        </div>
                                                        <span className=''>{room.guests.childs ? room.guests.childs : 0}</span>
                                                        <div onClick={() => incrementTravelers("childs", index)} className='flex justify-center items-center h-5 w-5 rounded-full border border-primary ml-3'>
                                                            <BsPlusLg className=' text-primary text-[14px]' />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                    }
                                </div>
                            </>
                        )
                    })
                }


                <div className=' w-full'>
                    <div className=' w-full mt-2 flex justify-between'>
                        <div onClick={addArotherRoom} className=' px-2 py-1 border border-primary rounded-md flex items-center gap-2'>
                            <AiFillPlusCircle className=' text-gray-400' />
                            <span>Add Another Room</span>
                        </div>
                        <button id='Close' className=' bg-yellow-400 font-semibold text-primary py-1 px-3 rounded hover:shadow-xl'>Done</button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BookingClass