import React from 'react'
import Carousel from 'react-multi-carousel';
import "react-multi-carousel/lib/styles.css";
import SliderItem from './SliderItem';

export default function Slider() {

    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 2000, min: 1400 },
            items: 2
        },
        desktop: {
            breakpoint: { max: 1400, min: 1024 },
            items: 2
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };

    return (
        <div className=' w-full flex justify-center items-center'>
            <div className='w-full xl:w-[1160px] px-5 pb-10 lg:pb-20 '>
                <div className='gd-carousel-wrapper mt-10 sha'>
                    <Carousel
                        arrows={true}
                        responsive={responsive}
                        className="gd-carousel"
                    >
                        <div className=' mr-5'>
                            <SliderItem />
                        </div>
                        <div className=' mr-5'>
                            <SliderItem />
                        </div>
                        <div className=' mr-5'>
                            <SliderItem />
                        </div>
                        <div className=' mr-5'>
                            <SliderItem />
                        </div>
                        <div className=' mr-5'>
                            <SliderItem />
                        </div>
                        <div className=' mr-5'>
                            <SliderItem />
                        </div>
                        <div className=' mr-5'>
                            <SliderItem />
                        </div>
                    </Carousel>
                </div>
            </div>
        </div>
    )
}
