import React, { useEffect } from 'react'
import { useState } from 'react'
import { AiOutlineCheck } from "react-icons/ai"
import { BsCheckLg } from 'react-icons/bs'

function FilterSelectItem({ title = "Biman Bangladesh Airlines", price }) {
    const [selectted, setSelected] = useState(false)
    const toggleSelect = () => {
        setSelected(!selectted)
    }

    return (
        <div className=' flex items-start cursor-pointer filterItem ' onClick={(e) => { toggleSelect() }}>
            <div className={` ${selectted ? "bg-primary border-primary " : "border-gray-500"} mr-1 w-4 h-4 bg-white border-[2px] rounded filterItem flex justify-center items-center`}>
                {
                    selectted &&
                    <BsCheckLg className=' text-[10px] text-white font-semibold' />
                }
            </div>
            <div className=' flex justify-between'>
                <p className=' text-gray-500 filterItem text-sm' id='filterItem'>{title}</p>
                {
                    price &&
                    <p className=' text-gray-500 text-sm'>BDT {price}</p>
                }
            </div>
        </div>
    )
}

export default FilterSelectItem