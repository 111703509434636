import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { AiOutlineClose, AiOutlineSearch, AiOutlineStar } from 'react-icons/ai'
import FilterSelectItem from '../FlightFilters/FilterSelectItem'
import AmenitiesList from './AmenitiesList'
import FacilitiesList from './FacilitiesList'
import HotelRangeComponent from './HotelPriceRange'

export default function HotelFilterComponent({ showFilterComponent, setShowFilterComponent }) {

    const [showFilter, setShowFilter] = useState(true)

    // resize 
    window.addEventListener("resize", (e) => {
        if (window.innerWidth >= 768) {
            if (!showFilter) {
                setShowFilter(true)
            }
        } else {
            setShowFilter(false)
        }
    })

    // initial load
    useEffect(() => {
        if (window.innerWidth >= 768) {
            setShowFilter(true)
        } else {
            setShowFilter(false)
        }
    }, [])

    useEffect(() => {
        if (showFilterComponent) {
            setShowFilter(true)
        }
    }, [showFilterComponent])

    const [facilities, setFacilities] = useState([
        "Bar", "Breakfast", "Non-smoking", "Restaurant", "Wi-Fi", "Parking", "24-hour Security", "Business Facilities", "Swimming Pool", "Room Service"
        , "Laundry Facilities", "Airport Shuttle", "Fitness Centre", "Outdoor Activities", "Air Conditioning", "Disability Friendly", "Elevator/Lift", "Balcony/Terrace"
    ])
    const [amenities, setAmenities] = useState([
        "Air Conditioning", "Balcony", "Bathtub", "Ceiling Fan", "Connecting Rooms", "Cot on Demand", "Disability Friendly", "Hairdryer", "Ironing Set", "Minibar", "Non Smoking Room", "Power Outlet",
        "Push-shower", "Safe/Locker", "Toiletries", "TV", "Wi-Fi"
    ])
    const [displayAmenities, setdisplayAmenites] = useState([])
    const [displayFacilities, setdisplayFacilities] = useState([])

    useEffect(() => {
        setdisplayAmenites([...amenities].slice(0, 5))
        setdisplayFacilities([...facilities].slice(0, 5))
    }, [])

    const [showFacilities, setShoFacilities] = useState(false)
    const [showAmenities, setShowAmenities] = useState(false)

    const toggleShowFacilities = () => {
        if (showAmenities) {
            setShowAmenities(false)
            setdisplayAmenites([...amenities].slice(0, 5))
        }
        if (!showFacilities) {
            setShoFacilities(true)
            setdisplayFacilities([...facilities])
        } else {
            setShoFacilities(false)
            setdisplayFacilities([...facilities].slice(0, 5))
        }
    }

    const toggleShoAmenities = () => {
        if (showFacilities) {
            setShoFacilities(false)
            setdisplayFacilities([...facilities].slice(0, 5))
        }
        if (!showAmenities) {
            setShowAmenities(true)
            setdisplayAmenites([...amenities])
        } else {
            setShowAmenities(false)
            setdisplayAmenites([...amenities].slice(0, 5))
        }
    }


    return (
        <div className={`${showFilter ? "fixed top-12 z-[10] md:z-0 left-0 md:static h-screen md:h-auto w-screen md:w-full" : "hidden"} `}>
            <div className=" w-full h-full bg-white shadow rounded-md">
                <div className=" py-3 px-5">
                    <div className=' flex items-center justify-between'>
                        <p className=" text-primary text-sm font-medium">Destination: 3 properties found</p>
                        <div className={`md:hidden`}>
                            <AiOutlineClose onClick={() => { setShowFilter(false); setShowFilterComponent(false) }} className=' text-gray-500 cursor-pointer text-lg' />
                        </div>

                    </div>
                    <div className=" mt-5">
                        <p className=" text-sm font-medium text-primary">Sort by</p>
                        <select name="" id="" className=" border border-r-gray-300 rounded px-2 py-1 mt-1 w-full text-sm text-gray-500">
                            <option value="popularity" className=" text-sm text-gray-500">Popularity</option>
                            <option value="price" className=" text-sm text-gray-500">Price</option>
                        </select>
                    </div>
                </div>
                <div className=" mt-5">
                    <div className=" px-5">
                        <p className=" text-primary font-semibold">Filter By</p>
                    </div>
                    <hr className=" my-3" />
                </div>
                <div className=" p-5">
                    <div>
                        <p className=" text-sm text-primary font-semibold">Popularity Name</p>
                        <div className=" w-full border rounded border-primary grid grid-cols-5 mt-1">
                            <div className=" col-span-4 relative border-r border-primary p-1">
                                <input type="text" className=" w-full placeholder:text-sm focus:placeholder:translate-x-1 transition-transform ease-linear duration-400  px-4 focus:outline-none" placeholder="Property Name" />
                            </div>
                            <div className=" flex justify-center items-center cursor-pointer">
                                <AiOutlineSearch className=" text-gray-600 text-lg" />
                            </div>
                        </div>
                    </div>
                    <hr className=" my-4" />
                    <div>
                        <p className=" text-sm text-primary font-semibold">Rating</p>
                        <div className=" w-full grid grid-cols-5 mt-2">
                            <div className="relative cursor-pointer">
                                <AiOutlineStar className=" text-4xl text-blue-300" />
                                <span className=" text-[12px] text-gray-400 absolute top-1/2 left-1/2 -translate-x-3 -translate-y-1/2">0+</span>
                            </div>
                            <div className="relative cursor-pointer">
                                <AiOutlineStar className=" text-4xl text-blue-300" />
                                <span className=" text-[12px] text-gray-400 absolute top-1/2 left-1/2 -translate-x-[8px] -translate-y-1/2">2</span>
                            </div>
                            <div className="relative cursor-pointer">
                                <AiOutlineStar className=" text-4xl text-blue-300" />
                                <span className=" text-[12px] text-gray-400 absolute top-1/2 left-1/2 -translate-x-[8px] -translate-y-1/2">3</span>
                            </div>
                            <div className="relative cursor-pointer">
                                <AiOutlineStar className=" text-4xl text-blue-300" />
                                <span className=" text-[12px] text-gray-400 absolute top-1/2 left-1/2 -translate-x-[8px] -translate-y-1/2">4</span>
                            </div>
                            <div className="relative cursor-pointer">
                                <AiOutlineStar className=" text-4xl text-blue-300" />
                                <span className=" text-[12px] text-gray-400 absolute top-1/2 left-1/2 -translate-x-[8px] -translate-y-1/2">5</span>
                            </div>
                        </div>
                    </div>
                    <hr className=" my-4" />
                    <div>
                        <HotelRangeComponent />
                    </div>
                    <hr className=" my-4" />
                    <div>
                        <p className=' font-medium text-primary text-sm'>Accommodation Type</p>
                        <div className='mt-2 text-gray-500'>
                            <FilterSelectItem title="Hotel" />
                        </div>
                    </div>
                    <hr className=" my-4" />
                    <div>
                        <p className=' font-medium text-primary text-sm'>Facilities</p>
                        <FacilitiesList facilities={displayFacilities} />
                        {
                            showFacilities ?
                                <p className=' text-primary font-semibold text-sm cursor-pointer text-right' onClick={toggleShowFacilities}>Less</p>
                                :
                                <p className=' text-primary font-semibold text-sm cursor-pointer text-right' onClick={toggleShowFacilities}>More</p>
                        }

                    </div>
                    <hr className=" my-4" />
                    <div>
                        <p className=' font-medium text-primary text-sm'>Amenities</p>
                        <AmenitiesList amenities={displayAmenities} />
                        {
                            showAmenities ?
                                <p className=' text-primary font-semibold text-sm cursor-pointer text-right' onClick={toggleShoAmenities}>Less</p>
                                :
                                <p className=' text-primary font-semibold text-sm cursor-pointer text-right' onClick={toggleShoAmenities}>More</p>
                        }

                    </div>
                    <hr className=" my-4" />
                    <div>
                        <p className=' font-medium text-primary text-sm'>Neighbourhood</p>
                        <div className=' mt-3 text-gray-500'>
                            <FilterSelectItem title="Buenos Aires" />
                        </div>
                    </div>
                </div>
                <hr className=' my-4' />
                <div className=' px-5 pb-5'>
                    <button className=' w-full bg-yellow-400 py-2 rounded-lg font-semibold text-primary'>Reset Filters</button>
                </div>
            </div>
        </div>
    )
}
