import React from 'react'
import { useState } from 'react'
import Carousel from 'react-multi-carousel';
import "react-multi-carousel/lib/styles.css";
import HotdealItem from './HotdealItem';
import { faChevronLeft, faChevronRight } from "react-icons/fa"

export default function Hotdeal() {

    const [showDealType, setShowDealType] = useState("all");
    const toggleShowDealType = (option) => {
        setShowDealType(option)
    }

    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 2000, min: 1400 },
            items: 2
        },
        desktop: {
            breakpoint: { max: 1400, min: 1024 },
            items: 2
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };

    return (
        <div className=' w-full flex justify-center items-center'>
            <div className='w-full xl:w-[1160px] px-5 py-5 '>
                <div className=' w-full flex justify-between'>
                    <div>
                        <h3 className=' text-primary text-base sm:text-lg md:text-xl lg:text-2xl font-bold'>Hot Deals</h3>
                    </div>
                    <div className=' px-[10px] py-[6px] rounded-[20px] flex gap-1 lg:gap-2 bg-white'>
                        <span className={` inline-block text-primary ${showDealType === "all" && "bg-gray-200"} hover:bg-gray-200 transition-all duration-75 ease-linear text-[12px] lg:text-sm px-1 lg:px-2 rounded-xl cursor-pointer`} onClick={() => { toggleShowDealType("all") }}>All</span>
                        <span className={` inline-block text-primary ${showDealType === "flight" && "bg-gray-200"} hover:bg-gray-200 transition-all duration-75 ease-linear text-[12px] lg:text-sm px-1 lg:px-2 rounded-xl cursor-pointer`} onClick={() => { toggleShowDealType("flight") }}>Flight</span>
                        <span className={` inline-block text-primary ${showDealType === "hotel" && "bg-gray-200"} hover:bg-gray-200 transition-all duration-75 ease-linear text-[12px] lg:text-sm px-1 lg:px-2 rounded-xl cursor-pointer`} onClick={() => { toggleShowDealType("hotel") }}>Hotel</span>
                        <span className={` inline-block text-primary ${showDealType === "others" && "bg-gray-200"} hover:bg-gray-200 transition-all duration-75 ease-linear text-[12px] lg:text-sm px-1 lg:px-2 rounded-xl cursor-pointer`} onClick={() => { toggleShowDealType("others") }}>Others</span>
                    </div>
                </div>
                {/* <div className=' w-full grid grid-cols-2 gap-5 mt-10'> */}
                <div className='gd-carousel-wrapper mt-20 sha'>
                    <Carousel
                        arrows={true}
                        responsive={responsive}
                        className="gd-carousel"
                    >
                        <div className=' mr-2'>
                            <HotdealItem />
                        </div>
                        <div className=' mr-2'>
                            <HotdealItem />
                        </div>
                        <div className=' mr-2'>
                            <HotdealItem />
                        </div>
                        <div className=' mr-2'>
                            <HotdealItem />
                        </div>
                        <div className=' mr-2'>
                            <HotdealItem />
                        </div>
                        <div className=' mr-2'>
                            <HotdealItem />
                        </div>
                        <div className=' mr-2'>
                            <HotdealItem />
                        </div>
                    </Carousel>
                </div>
            </div>
        </div>
    )
}
