import GoogleMapReact from 'google-map-react';

const AnyReactComponent = ({ text }) => <div>{text}</div>;

export default function Mapcomponent() {
    const defaultProps = {
        center: {
            lat: 10.99835602,
            lng: 77.01502627
        },
        zoom: 11
    };

    return (
        // Important! Always set the container height explicitly
        <div className=' h-52 w-full'>
            <GoogleMapReact
                bootstrapURLKeys={{
                    key: "",
                    language: 'ru',
                    region: 'ru',
                    libraries: ['places'],
                }}
                defaultCenter={defaultProps.center}
                defaultZoom={defaultProps.zoom}

            >
                <AnyReactComponent
                    lat={59.955413}
                    lng={30.337844}
                    text="My Marker"
                />
            </GoogleMapReact>

        </div>
    );
}