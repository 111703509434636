import React from 'react'
import { useEffect } from 'react'
import { AiOutlineCloseCircle } from 'react-icons/ai'
import { BsArrowLeft } from 'react-icons/bs'
import { useLocation } from 'react-router-dom'
import getDate from '../../../utils/getDate'
import BookingClass from './BookingClass'
import CalenderComponent from './CalenderComponent'
import ReturnCalenderComponent from './ReturnCalenderCompponent'
import SearchFields from './SearchFields'

export default function SingleCityFlightItem({ travelers, setTravelers, ticketClass, setTicketClass, totalTraveler, clickedClassOption, clickedSearchOption, switchTravelData, toggleSearchOption, singleCityData, searchOption, changeFlightData, clickedDateOption, showCalander, retrnshowCalander, toggleClassOption, travelType, setTravelType, changeDateOption, setClickedDateOption, setSingleCityData, initialData, searchFlight }) {

    const location = useLocation()

    return (
        <div className={`${location.pathname === "/" ? "w-full" : " grid grid-cols-12 gap-2"}`}>
            <div className={`grid grid-cols-1 md:grid-cols-4 gap-2 relative ${location.pathname === "/" ? "" : " col-span-12 md:col-span-10"}`}>
                <div className=' relative w-full h-full'>
                    <div onClick={(e) => { toggleSearchOption("from", e) }} className={` border w-full h-full rounded-xl px-2 py-1 text-left cursor-pointer ${searchOption === "from" && clickedSearchOption && " bg-gray-100"}`}>
                        <p className=" text-primary text-[12px]">FROM</p>
                        <p className=' font-bold text-primary lg:text-base text-sm'>{singleCityData.flightFrom.city}</p>
                        <p className='text-primary text-[10px] lg:text-[12px]'>{singleCityData.flightFrom.name}</p>
                        {
                            clickedSearchOption && searchOption === "from" &&
                            <SearchFields initialData={initialData} flightFrom={singleCityData.flightFrom} flightTo={singleCityData.flightTo} changeFlightData={changeFlightData} option="from" />
                        }

                    </div>
                    <div onClick={switchTravelData} className=' cursor-pointer absolute top-1/2 -translate-y-1/2 -right-3 lg:-right-5 bg-white border rounded-full flex flex-col justify-center items-center h-8 lg:h-10 w-8 lg:w-10 z-[3]'>
                        <BsArrowLeft className=' text-[12px] translate-y-[2px] text-gray-400' />
                        <BsArrowLeft className=' rotate-180 -translate-y-[2px] text-[12px] text-gray-400' />
                    </div>
                </div>
                <div onClick={(e) => { toggleSearchOption("to", e) }} className={` border rounded-xl px-3 lg:px-5 py-1 text-left cursor-pointer relative ${searchOption === "to" && clickedSearchOption && " bg-gray-100"}`}>
                    <p className=" text-primary text-[12px]">TO</p>
                    <p className='  font-bold text-primary lg:text-base text-sm'>{singleCityData.flightTo.city}</p>
                    <p className='text-primary text-[10px] lg:text-[12px]'>{singleCityData.flightTo.name}</p>
                    {
                        clickedSearchOption && searchOption === "to" &&
                        <SearchFields initialData={initialData} flightFrom={singleCityData.flightFrom} flightTo={singleCityData.flightTo} changeFlightData={changeFlightData} option="to" />
                    }
                </div>
                <div className=' grid grid-cols-2 border rounded-lg'>
                    <div onClick={(e) => { showCalander(); toggleSearchOption("depature", e) }} className={` text-left px-2 py-1 relative border-r cursor-pointer ${searchOption === "depature" && clickedDateOption && "bg-gray-100"}`}>
                        <p className=" text-primary text-[12px]">JOURNEY DATE</p>
                        <p className=' font-bold text-primary lg:text-base text-sm'>{singleCityData.depatureData.date}</p>
                        <p className=' text-primary text-[10px] lg:text-[12px]'>{singleCityData.depatureData.day}</p>
                        <CalenderComponent option="Depature" setClickedDateOption={setClickedDateOption} changeDateOption={changeDateOption} />
                    </div>
                    <div onClick={(e) => { retrnshowCalander(e); toggleSearchOption("return", e) }} className={` text-left px-2 py-1 relative cursor-pointer ${searchOption === "return" && clickedDateOption && "bg-gray-100"}`}>
                        <p className=" text-primary text-[12px]">RETURN DATE</p>
                        {
                            travelType === "round-way" ?
                                <>
                                    <p className=' font-bold text-primary lg:text-base text-sm'>{singleCityData.returnData?.date}</p>
                                    <p className=' text-primary text-[10px] lg:text-[12px]'>{singleCityData.returnData?.day}</p>
                                    <div onClick={() => { setTravelType("one-way"); setSingleCityData({ ...singleCityData, returnData: {} }) }} className=' flex justify-center items-center absolute top-1/2 -translate-y-1/2 right-1 text-2xl text-gray-400 cursor-pointer'>
                                        <AiOutlineCloseCircle id='close-return-flight' className=' ' />
                                    </div>
                                </>
                                :
                                <>
                                    <p className=' text-[10px] text-primary'>Save more on return flight</p>
                                </>
                        }
                        <ReturnCalenderComponent option="return" setClickedDateOption={setClickedDateOption} changeDateOption={changeDateOption} depatureData={singleCityData.depatureData} />
                    </div>
                </div>
                <div onClick={(e) => { toggleClassOption(e); toggleSearchOption("traveler", e) }} className={` border rounded-lg px-2 py-1 text-left cursor-pointer relative ${searchOption === "traveler" && clickedClassOption && "bg-gray-100"}`}>
                    <p className="  text-primary text-[10px] lg:text-[12px]">Traveler, Class</p>
                    <p className='  font-bold text-base lg:text-lg text-primary'>{totalTraveler} Traveler</p>
                    <p className=' ttext-primary text-[10px] lg:text-[12px]'>Economy</p>
                    {
                        clickedClassOption &&
                        <BookingClass ticketClass={ticketClass} setTicketClass={setTicketClass} travelers={travelers} setTravelers={setTravelers} />
                    }
                </div>
            </div>
            <div className={`${location.pathname === "/" ? "hidden" : "block col-span-12 md:col-span-2"}`}>
                <button onClick={searchFlight} className={` w-full h-full shadow py-[10px] text-center bg-yellow-400 text-sm sm:text-base md:text-lg text-primary font-semibold rounded-md`}>Modify</button>
            </div>
        </div>
    )
}
