import React from 'react'
import { useSelector } from 'react-redux'
import Hotdeal from '../Components/Hotdeals/Hotdeal'
import HotelTourSlide from '../Components/HotelTureSlide/HotelTureSlide'
import SearchComponent from '../Components/Search/SearchComponent'
import Slider from '../Components/Slider/Slider'
import travelPhoto from "../Photos/season-of-travel-lg.jfif"


function Home() {
    const SearchType = useSelector((state) => state.SearchType.SearchType)
    return (
        <div className=' w-full '>
            <div className=" w-full h-[600px] flex justify-center bg-hero-image bg-center bg-no-repeat bg-cover">
                <div className=' w-full h-full flex justify-center items-start mt-32 lg:mt-48'>
                    <div className='  w-full xl:w-[1160px] flex  justify-center px-5 '>
                        <SearchComponent Home />
                    </div>
                </div>
            </div>
            <div className=' w-full flex justify-center items-center'>
                <div className='w-full xl:w-[1160px] min-h-44 flex justify-center px-5 py-10 h-60 '>
                    <img src={travelPhoto} alt="" className=' w-full h-full object-fill rounded-xl' />
                </div>
            </div>
            <Hotdeal />
            <Slider />
            {
                SearchType === "hotel" &&
                <HotelTourSlide title="Popular Hotels" option="hotel" />
            }
            {
                SearchType === "tour" &&
                <>
                    <HotelTourSlide title="Popular Destinations" />
                    <HotelTourSlide title="Popular Tours" />
                </>
            }
        </div>

    )
}

export default Home