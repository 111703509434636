import React from 'react'
import Carousel from 'react-multi-carousel';
import "react-multi-carousel/lib/styles.css";
import SliderItem from '../Hotel/SliderItem';
import photo1 from "../../Photos/hotel/details/hotel (1).jpg"
import photo2 from "../../Photos/hotel/details/hotel (2).jpg"
import photo3 from "../../Photos/hotel/details/hotel (3).jpg"
import photo4 from "../../Photos/hotel/details/hotel (4).jpg"
import { HiOutlineLocationMarker } from "react-icons/hi"
import { AiFillSave, AiOutlineCheck, AiOutlineClose, AiOutlineInfoCircle } from "react-icons/ai"
import { TbBeach } from "react-icons/tb"
import { ImLocation } from "react-icons/im"
import { RiEarthFill, RiFileList3Line } from "react-icons/ri"
import Mapcomponent from '../../Components/Hotdeals/Mapcomponent'
import { MdTipsAndUpdates } from "react-icons/md"
import { BsClock, BsFillCalendar2CheckFill, BsFillClockFill, BsPeopleFill } from "react-icons/bs"
import { GiAirBalloon, GiMiniSubmarine } from "react-icons/gi"
import { Link, } from 'react-scroll'
import TourDetailsPageSearch from '../../Components/Search/TourDetailsPageSearch';

function TourBookingDetails() {

    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 2000, min: 1400 },
            items: 1
        },
        desktop: {
            breakpoint: { max: 1400, min: 1024 },
            items: 1
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };


    return (
        <div className=' pt-14 md:pt-20 w-full'>
            <div className='w-full xl:w-[1160px] mx-auto px-5 py-10'>
                <div className=' w-full grid grid-cols-1 lg:grid-cols-2 gap-1 bg-white pb-5'>
                    <div className=' grid grid-cols-6 gap-3'>
                        <div className='gd-carousel-wrapper  col-span-6 sm:col-span-5'>
                            <Carousel
                                arrows={true}
                                responsive={responsive}
                                className="gd-carousel"
                                autoPlay={true}
                                autoPlaySpeed={3000}
                                infinite={true}

                            >
                                <div className=' mr-5 h-full'>
                                    <SliderItem />
                                </div>
                                <div className=' mr-5 h-full'>
                                    <SliderItem />
                                </div>
                                <div className=' mr-5 h-full'>
                                    <SliderItem />
                                </div>
                                <div className=' mr-5 h-full'>
                                    <SliderItem />
                                </div>
                                <div className=' mr-5 h-full'>
                                    <SliderItem />
                                </div>
                                <div className=' mr-5 h-full'>
                                    <SliderItem />
                                </div>
                                <div className=' mr-5 h-full'>
                                    <SliderItem />
                                </div>
                            </Carousel>
                        </div>
                        <div className=' col-span-1 flex sm:flex-col gap-3'>
                            <img src={photo1} alt="" className='' />
                            <img src={photo2} alt="" className='' />
                            <img src={photo3} alt="" className='' />
                            <img src={photo4} alt="" className='' />
                        </div>
                    </div>

                    <div className=' grid grid-cols-5 gap-3 py-2'>
                        <div className=' col-span-5 sm:col-span-4 px-2'>
                            <div className=' mt-1'>
                                <p className=' font-bold text-xl text-primary'>Windy Terrace Boutique Hotel</p>
                                <div className=' flex items-center gap-1 mt-1'>
                                    <ImLocation className=' text-blue-400 text-[12px]' />
                                    <p className=' text-[12px] text-blue-400'>Plot-40, Block-C, Kolatoli, Cox's Bazar, Bangladesh</p>
                                </div>
                            </div>
                            <div className=' mt-2'>
                                <Mapcomponent />
                            </div>
                            <div className='flex gap-2 mt-5'>
                                <div className=' flex items-center gap-1'>
                                    <BsFillClockFill className=' text-gray-500 text-sm' />
                                    <p className=' text-[12px] text-gray-500'>9 hours</p>
                                </div>
                                <div className=' flex items-center gap-1'>
                                    <BsPeopleFill className=' text-gray-500 text-sm' />
                                    <p className=' text-[12px] text-gray-500'> From 1 to 48 people</p>
                                </div>
                            </div>
                            <div className=''>
                                <div className=" flex gap-2 mt-2">
                                    <div className="  flex items-center gap-1 text-gray-500">
                                        <AiFillSave className=" text-sm" />
                                        <p className="  text-[12px]">Free cancellation up to twenty-four (24) hours before the start of the tour.</p>
                                    </div>
                                </div>
                                <div className=" flex gap-2 mt-5">
                                    <div className=" bg-gray-100 rounded px-2 py-1 flex items-center gap-1 text-gray-500">
                                        <GiAirBalloon className=" text-sm" />
                                        <p className="  text-[12px]">Sightseeing</p>
                                    </div>
                                    <div className=" bg-gray-100 rounded px-2 py-1 flex items-center gap-1 text-gray-500">
                                        <TbBeach className=" text-sm" />
                                        <p className="  text-[12px]">Day Tour</p>
                                    </div>
                                    <div className=" bg-gray-100 rounded px-2 py-1 flex items-center gap-1 text-gray-500">
                                        <GiMiniSubmarine className=" text-sm" />
                                        <p className="  text-[12px]">Marine drive</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className=' mt-5 bg-white'>
                    <div className=' bg-white p-5 sticky top-10 md:top-20 left-0 z-50'>
                        <div className=' flex items-center gap-5 border-b'>
                            <Link activeClass="border-b-2 border-blue-500 text-blue-400" to="details" className={` pb-3 text-gray-500 `} spy={true} smooth={true} offset={-160} duration={500}>
                                <span className=' text-[12px] cursor-pointer'>Details</span>
                            </Link>
                            <Link activeClass="border-b-2 border-blue-500 text-blue-400" to="options" className='pb-3 text-gray-500' spy={true} smooth={true} offset={-150} duration={500}>
                                <span className=' text-[12px] cursor-pointer'>Options</span>
                            </Link>

                            <Link activeClass="border-b-2 border-blue-500 text-blue-400" to="policy" spy={true} className='pb-3 text-gray-500' smooth={true} offset={-150} duration={500}>
                                <span className=' text-[12px] cursor-pointer'>Ploicy</span>
                            </Link>
                        </div>
                    </div>
                    <div className=' grid grid-cols-6'>
                        <div className=' col-span-6 md:col-span-4'>
                            {/* details Section start */}
                            <div id='details' className=' px-5'>
                                <div className=' border-b pb-3'>
                                    <div className=' flex gap-2 items-center'>
                                        <RiEarthFill className=' text-gray-500 text-lg' />
                                        <p className=' text-xl font-bold text-primary'>Overview</p>
                                    </div>
                                    <p className=' text-gray-600 mt-5 leading-6 text-sm pl-4'>Imagine exploring the tourist spots of the world's largest sea beach, Cox's Bazar in a double-decker tourist bus. Having the wide ocean view on one side and hill view on the other, this tour is an excellent opportunity for you to enjoy an entire day while fulfilling a major portion of your Cox's Bazar checklist. Driving through the iconic marine drive, Aquaholic Tourist Caravan offers you a stoppage at every spot to hop around comfortably. This tour also comes with special meal services, a mini library, washroom and refreshments - all inside the tourist bus! Overall, this unique experience of roaming around and visiting spots in a double-decker tourist bus is definitely going to be one of the activities to cherish with your friends or family in Cox's Bazar.</p>
                                </div>
                                <div className=' pt-5 pb-5 border-b'>
                                    <div className=' flex gap-2 items-center'>
                                        <HiOutlineLocationMarker className=' text-gray-500 text-lg' />
                                        <p className=' text-xl font-bold text-primary'>Location</p>
                                    </div>
                                    <p className=' text-gray-500 text-sm mt-3 pl-4'><span className=' font-semibold text-primary'>Pick-up</span>: Ground Floor, Motel Upal, Shaibal Road, Cox's Bazar</p>
                                </div>
                                <div className=' pt-5 pb-5 border-b'>
                                    <div className=' flex gap-2 items-center'>
                                        <BsClock className=' text-gray-500 text-lg' />
                                        <p className=' text-xl font-bold text-primary'>Timing</p>
                                    </div>
                                    <p className=' text-gray-500 text-sm mt-3 pl-4'>9:00 AM - 6:00 PM</p>
                                    <p className=' text-gray-500 text-sm pl-4'><span className=' font-semibold text-primary'>Duration</span>: 9 hours</p>
                                </div>
                                <div className=' pt-5 pb-5 border-b'>
                                    <div className=' flex gap-2 items-center'>
                                        <BsClock className=' text-gray-500 text-lg' />
                                        <p className=' text-xl font-bold text-primary'>Timing</p>
                                    </div>
                                    <div className=' pl-4 mt-3'>
                                        <div className=' flex items-center gap-2 mb-1'>
                                            <AiOutlineCheck className=' text-green-500' />
                                            <p className='text-gray-500 text-sm'>All Entry Tickets of Hopping Destinations</p>
                                        </div>
                                        <div className=' flex items-center gap-2 mb-1'>
                                            <AiOutlineCheck className=' text-green-500' />
                                            <p className='text-gray-500 text-sm'>Welcome Breakfast</p>
                                        </div>
                                        <div className=' flex items-center gap-2 mb-1'>
                                            <AiOutlineCheck className=' text-green-500' />
                                            <p className='text-gray-500 text-sm'>Lunch Platter</p>
                                        </div>
                                        <div className=' flex items-center gap-2 mb-1'>
                                            <AiOutlineCheck className=' text-green-500' />
                                            <p className='text-gray-500 text-sm'>Evening Snacks</p>
                                        </div>
                                        <div className=' flex items-center gap-2 mb-1'>
                                            <AiOutlineCheck className=' text-green-500' />
                                            <p className='text-gray-500 text-sm'>Washroom</p>
                                        </div>
                                        <div className=' flex items-center gap-2 mb-1'>
                                            <AiOutlineCheck className=' text-green-500' />
                                            <p className='text-gray-500 text-sm'>Library</p>
                                        </div>
                                        <div className=' flex items-center gap-2 mb-1'>
                                            <AiOutlineCheck className=' text-green-500' />
                                            <p className='text-gray-500 text-sm'>WIFI</p>
                                        </div>
                                        <div className=' flex items-center gap-2 mb-1'>
                                            <AiOutlineCheck className=' text-green-500' />
                                            <p className='text-gray-500 text-sm'>Guide</p>
                                        </div>
                                        <div className=' flex items-center gap-2 mb-1'>
                                            <AiOutlineCheck className=' text-green-500' />
                                            <p className='text-gray-500 text-sm'>Wrist band</p>
                                        </div>
                                        <div className=' flex items-center gap-2 mb-1'>
                                            <AiOutlineCheck className=' text-green-500' />
                                            <p className='text-gray-500 text-sm'>First Aid Kit (In case of emergency)</p>
                                        </div>
                                        <div className=' flex items-center gap-2 mb-1'>
                                            <AiOutlineClose className=' text-red-500' />
                                            <p className='text-gray-500 text-sm'>Personal expenses</p>
                                        </div>
                                        <div className=' flex items-center gap-2 mb-1'>
                                            <AiOutlineClose className=' text-red-500' />
                                            <p className='text-gray-500 text-sm'>Anything else that isn't mentioned on Inclusions</p>
                                        </div>
                                    </div>
                                </div>
                                <div className=' border-b pb-3'>
                                    <div className=' flex gap-2 items-center'>
                                        <RiFileList3Line className=' text-gray-500 text-lg' />
                                        <p className=' text-xl font-bold text-primary'>Description</p>
                                    </div>
                                    <p className=' text-gray-600 mt-5 leading-6 text-sm pl-4'>Aquaholic Tourist Caravan is the first hop-on hop-off service in Bangladesh. For the first time in Bangladesh, it brings to you the opportunity to experience the majestic aura of the Marine Drive road in such a unique setting. The travelers will be starting for the day long tour at 09:00 AM. The daylong will be covering all the spots of Marine Drive as you are entertained with fantastic meals on board. The travelers will be dropped-off at Kolatoli at 06:00 PM.</p>
                                    <div className=' pl-4'>
                                        <div className=' mt-5'>
                                            <p className=' text-gray-800 text-sm font-semibold'>The Caravan is facilitated with:</p>
                                            <ul className=' text-gray-500 text-sm list-disc ml-5'>
                                                <li className=' ml-5 mb-1 mt-2'>Washroom</li>
                                                <li className=' ml-5 mb-1'>Kitchen</li>
                                                <li className=' ml-5 mb-1'>Library</li>
                                                <li className=' ml-5 mb-1'>CC Camera Surveillance</li>
                                                <li className=' ml-5 mb-1'>Skilled Guide</li>
                                                <li className=' ml-5 mb-1'>Experienced Chef</li>
                                            </ul>
                                        </div>
                                        <div className=' mt-5'>
                                            <p className=' text-gray-800 text-sm font-semibold'>Hopping Destinations: </p>
                                            <ul className=' text-gray-500 text-sm list-disc ml-5'>
                                                <li className=' ml-5 mb-1 mt-2'>Patuartak beach</li>
                                                <li className=' ml-5 mb-1'>Shamlapur Hill View Beach</li>
                                                <li className=' ml-5 mb-1'>Teknaf Beach</li>
                                                <li className=' ml-5 mb-1'>Sabrang Zero Point</li>
                                            </ul>
                                        </div>
                                        <div className=' mt-5'>
                                            <p className=' text-gray-800 text-sm font-semibold'>Complementary Food Menu:</p>
                                            <p className='text-gray-500 text-sm my-2'>Welcome Snacks: Sandwich, Coconut Water, Apple, Mineral Water</p>
                                            <p className='text-gray-500 text-sm my-2'>Lunch Platter: Chicken Fried Rice, Cashew Nut Salad, Vegetable, Masala Chicken, Cold Drinks, Mineral Water</p>
                                            <p className='text-gray-500 text-sm my-2'>Evening Snacks: Fried Chicken, Bun, French Fry, Sauce, Green Tea, Mineral Water</p>
                                        </div>
                                    </div>
                                </div>
                                <div className=' pt-5 pb-5 border-b'>
                                    <div className=' flex gap-2 items-center'>
                                        <AiOutlineInfoCircle className=' text-gray-500 text-lg' />
                                        <p className=' text-xl font-bold text-primary'>Additional Information</p>
                                    </div>
                                    <ul className=' text-gray-500 text-sm list-disc ml-5'>
                                        <li className=' ml-5 mb-1 mt-5'>Tickets of Aquaholic Tourist Caravan are highly dependent on the availability. GoZayaan will issue tickets that are available and does not promise to provide any specific seat(s) for a booking.</li>
                                        <li className=' ml-5 mb-1'>Confirmation will be received at the time of booking</li>
                                        <li className=' ml-5 mb-1'>Children must be accompanied by an adult</li>
                                        <li className=' ml-5 mb-1'>Face masks are required for travelers in public areast</li>
                                        <li className=' ml-5 mb-1'>Social distancing is enforced throughout the experience</li>
                                        <li className=' ml-5 mb-1'>Due to maintenance work on Rezu khal Bridge (15 km from kolatoli) Aquaholic Tourist Caravan will provide Special transportation service till the bridge. The remaining distance of the sixty-five (65) km will be facilitated by the Caravan.</li>
                                    </ul>
                                </div>
                                <div className=' pt-5 pb-5 border-b'>
                                    <div className=' flex gap-2 items-center'>
                                        <MdTipsAndUpdates className=' text-gray-500 text-lg' />
                                        <p className=' text-xl font-bold text-primary'>Travel Tips</p>
                                    </div>
                                    <ul className=' text-gray-500 text-sm list-disc ml-5'>
                                        <li className=' ml-5 mb-1 mt-5'>Carry drinking water. Stay hydrated, travel healthy.</li>
                                        <li className=' ml-5 mb-1'>It is advised to carry a set of fresh clothes for the beach.</li>
                                        <li className=' ml-5 mb-1'>Always be respectful of the rules and guidelines of the tourist spots.</li>
                                        <li className=' ml-5 mb-1'>Please do not litter. Use a polybag as a portable trash bin.</li>
                                    </ul>
                                </div>
                            </div>
                            {/* details Section end */}
                            {/* options section start */}
                            <div id='options'>
                                <div className=' w-full p-5 mt-5'>
                                    <div className=' w-full border-b pb-5'>
                                        <div className=' flex gap-2 items-center'>
                                            <MdTipsAndUpdates className=' text-gray-500 text-lg' />
                                            <p className=' text-xl font-bold text-primary'>Options</p>
                                        </div>
                                        <div className=' w-full mt-5'>
                                            <div className=' w-1/2 flex justify-between items-center mb-10'>
                                                <div>
                                                    <p className=' text-sm'>Option 1</p>
                                                    <p className=' text-sm font-bold text-gray-500'>Upper Deck</p>
                                                </div>
                                                <img src={photo1} alt="" className=' rounded-md h-14' />
                                            </div>
                                            <div className=' w-1/2 flex justify-between items-center'>
                                                <div>
                                                    <p className=' text-sm'>Option 2</p>
                                                    <p className=' text-sm font-bold text-gray-500'>Lower Deck</p>
                                                </div>
                                                <img src={photo2} alt="" className=' rounded-md h-14' />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* options section end */}

                            {/* Policy section start */}
                            <div id='policy' className=' w-full bg-white p-5 mt-5 pb-10'>
                                <div className=' flex gap-2 items-center'>
                                    <BsFillCalendar2CheckFill className=' text-gray-500' />
                                    <p className=' text-lg font-bold text-primary'>Policy</p>
                                </div>
                                <div className=' pl-5'>
                                    <div className=' mt-5'>
                                        <p className=' text-primary text-sm font-semibold'>Cancellation</p>
                                        <ul className=' text-gray-500 text-sm list-disc ml-5'>
                                            <li className=' ml-5 mb-1 mt-2'>To cancel any tour, an email has to be sent to tours@gozayaan.com mentioning the tour booking ID and details about the cancellatio</li>
                                            <li className=' ml-5 mb-1'>The time of sending the email will be considered as the time of cancellation.
                                            </li>
                                            <li className=' ml-5 mb-1'>he email will be considered as the final application for cancellation. A phone call to the Go Zayaan hotline number or any other team member of Go Zayaan will not be considered as a request for cancellation.</li>
                                        </ul>
                                    </div>
                                    <div className=' mt-5'>
                                        <p className=' text-primary text-sm font-semibold'>Refund</p>
                                        <ul className=' text-gray-500 text-sm list-disc ml-5'>
                                            <li className=' ml-5 mb-1 mt-2'>The full amount of the tour fee will be refunded if the booking is canceled twenty-four (24) hours prior to the start of the experience/tour.</li>
                                            <li className=' ml-5 mb-1'>No refund will be provided if the booking is canceled less than twenty-four (24) hours prior to the start of the experience/tour.
                                            </li>
                                            <li className=' ml-5 mb-1'>Convenience fee is non-refundable and will be deducted from the paid amount.</li>
                                            <li className=' ml-5 mb-1'>All refunds will be processed within seven (7) working days.</li>
                                        </ul>
                                    </div>
                                    <div className=' mt-5'>
                                        <p className=' text-primary text-sm font-semibold'>Child Policy</p>
                                        <ul className=' text-gray-500 text-sm list-disc ml-5'>
                                            <li className=' ml-5 mb-1 mt-2'>No charge will be applied for kids below three (3) years old.</li>
                                            <li className=' ml-5 mb-1'>BDT 1,599 has to be paid for anyone between three (3) and seven (7) years old.
                                            </li>
                                            <li className=' ml-5 mb-1'>Full price has to be paid for anyone who is seven (7) years old.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            {/* Policy section end */}
                        </div>
                        <div className=' col-span-6 md:col-span-2 pr-2'>
                            <div className=' bg-white shadow-md rounded-md p-5 sticky top-36 right-5'>
                                <TourDetailsPageSearch />
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default TourBookingDetails