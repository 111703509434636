import React, { useState } from 'react'
import { useEffect } from 'react';
import getDate from '../../../utils/getDate';
import getReturnFormatedDate from '../../../utils/getFormatedDate';


function ReturnCalenderComponent({ changeDateOption, option, depatureData }) {
    const [min, setMin] = useState("");
    useEffect(() => {
        const returnDate = getReturnFormatedDate(new Date(depatureData.date))
        setMin(returnDate)
    }, [depatureData])

    return (
        <div className=' flex justify-center sm:justify-start'>
            <input type="date" id="returncalander" onChange={(e) => changeDateOption(getDate(e.target.value), option)} min={min} className="w-92 max-h-0 opacity-0 text-red-500 py-1 focus:outline-none focus:ring-1 rounded-md" />
        </div>
    )
}

export default ReturnCalenderComponent