import { BrowserRouter, Routes, Route } from "react-router-dom";
import Laout from "./client/Components/Layout/Laout";
import FlightBookingDetails from "./client/Pages/Flight/FlightBookingDetails";
import FlightList from "./client/Pages/Flight/FlightList";
import Home from "./client/Pages/Home";
import HotelBookingDetails from "./client/Pages/Hotel/HotelBookingDetails";
import HotelList from "./client/Pages/Hotel/HotelList";
import Profile from "./client/Pages/Profile";
import ProfileAddMember from "./client/Pages/ProfileAddMember";
import ProfileBooking from "./client/Pages/ProfileBooking";
import ProfilePrementLink from "./client/Pages/ProfilePamentLinks";
import ProfilePasswordChange from "./client/Pages/ProfilePasswordChange";
import TourBookingDetails from "./client/Pages/Tour/TourBookingDetails";
import TourList from "./client/Pages/Tour/TourList";

import { HashRouter } from "react-router-dom";

function App() {
  return (
    <HashRouter>
      <Routes>
        {/* client */}
        <Route
          path="/"
          element={
            <Laout>
              <Home />
            </Laout>
          }
        />
        <Route
          path="/flight/list"
          element={
            <Laout>
              {" "}
              <FlightList />{" "}
            </Laout>
          }
        />
        <Route
          path="/hotel/list"
          element={
            <Laout>
              {" "}
              <HotelList />{" "}
            </Laout>
          }
        />
        <Route
          path="/tour/list"
          element={
            <Laout>
              {" "}
              <TourList />{" "}
            </Laout>
          }
        />
        <Route
          path="/flight/booking-details"
          element={
            <Laout>
              {" "}
              <FlightBookingDetails />{" "}
            </Laout>
          }
        />
        <Route
          path="/hotel/booking-details"
          element={
            <Laout>
              {" "}
              <HotelBookingDetails />{" "}
            </Laout>
          }
        />
        <Route
          path="/tour/booking-details"
          element={
            <Laout>
              {" "}
              <TourBookingDetails />{" "}
            </Laout>
          }
        />
        <Route
          path="/profile"
          element={
            <Laout>
              <Profile />
            </Laout>
          }
        />
        <Route
          path="/profile/booking"
          element={
            <Laout>
              {" "}
              <ProfileBooking />{" "}
            </Laout>
          }
        />
        <Route
          path="/profile/links"
          element={
            <Laout>
              <ProfilePrementLink />
            </Laout>
          }
        />
        <Route
          path="/profile/fnf"
          element={
            <Laout>
              {" "}
              <ProfileAddMember />{" "}
            </Laout>
          }
        />
        <Route
          path="/profile/change-password"
          element={
            <Laout>
              <ProfilePasswordChange />
            </Laout>
          }
        />
      </Routes>
    </HashRouter>
  );
}

export default App;
