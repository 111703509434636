import React from 'react'
import { useState } from 'react'
import { MdOutlineLightMode, MdWbTwilight } from 'react-icons/md'

function FilterTimeComponent({ title }) {
    const [selectted, setSelected] = useState([])

    const toggleSelect = (name) => {
        if (selectted.includes(name)) {
            setSelected(selectted.filter((s) => s !== name))
        } else {
            setSelected([...selectted, name])
        }
    }


    return (
        <div className=' pb-2'>
            <p className=' text-primary text-sm'>{title}</p>
            <div className=' grid grid-cols-4 gap-[1px] border rounded border-gray-100'>
                <div className={` px-[6px] py-1 text-center cursor-pointer ${selectted.includes("morning") ? " bg-primary text-white" : " border-r border-gray-300"}`} onClick={(e) => { toggleSelect("morning") }}>
                    <div className=' flex justify-center'>
                        <MdOutlineLightMode className=' text-lg mb-1' />
                    </div>
                    <p className={` text-[12px] ${selectted.includes("morning") ? "text-white" : "text-gray-600"} `}>00-06</p>
                </div>

                <div className={` px-[6px] py-1 text-center cursor-pointer ${selectted.includes("noon") ? " bg-primary text-white" : " border-r border-gray-300"}`} onClick={(e) => { toggleSelect("noon") }}>
                    <div className=' flex justify-center'>
                        <MdOutlineLightMode className=' text-lg mb-1' />
                    </div>
                    <p className={` text-[12px] ${selectted.includes("noon") ? "text-white" : "text-gray-600"} `}>06-12</p>
                </div>

                <div className={` px-[6px] py-1 text-center cursor-pointer ${selectted.includes("evening") ? " bg-primary text-white" : " border-r border-gray-300"}`} onClick={(e) => { toggleSelect("evening") }}>
                    <div className=' flex justify-center'>
                        <MdWbTwilight className=' text-xl' />
                    </div>
                    <p className={` text-[12px] ${selectted.includes("evening") ? "text-white" : "text-gray-600"} `}>12-18</p>
                </div>
                <div className={` px-[6px] py-1 text-center cursor-pointer ${selectted.includes("nigth") ? " bg-primary text-white" : " border-0 border-gray-300"}`} onClick={(e) => { toggleSelect("nigth") }}>
                    <div className=' flex justify-center'>
                        <MdWbTwilight className=' text-xl' />
                    </div>
                    <p className={` text-[12px] ${selectted.includes("nigth") ? "text-white" : "text-gray-600"} `}>18-00</p>
                </div>
            </div>
        </div>
    )
}

export default FilterTimeComponent