import React from 'react'
import { useState } from 'react'

function FilterSegmentLength({ l }) {
    const [selectted, setSelected] = useState(false)
    const toggleSelect = () => {
        setSelected(!selectted)
    }

    return (
        <div className=' flex cursor-pointer filterLengthItem ' onClick={(e) => { toggleSelect() }}>
            <div className={` ${selectted ? "bg-primary border-primary text-white" : "border-gray-500"} w-6 h-6 bg-white border rounded filterLengthItem flex justify-center items-center`}>
                {l}
            </div>
        </div>
    )
}

export default FilterSegmentLength