import React from 'react'
import flightPhoto from "../../Photos/hotel/hotel (1).jpg"

export default function HotelTourSlideItem({ option }) {
    return (
        <div className=' w-full h-full rounded overflow-hidden relative group cursor-pointer'>
            <div className=' w-full h-52'>
                <img src={flightPhoto} alt="" className='  object-cover w-full h-full group-hover:scale-110 transition-all duration-300 ease-linear' />
            </div>
            <div className=' w-full h-full absolute top-0 left-0 bg-black bg-opacity-0 group-hover:bg-opacity-20 flex items-end'>
                {
                    option === "hotel" ?
                        <div className=' bg-black bg-opacity-25 py-2 w-full px-1'>
                            <p className=' text-lg font-semibold text-white'>The Place Luxury Resort</p>
                            <p className=' text-white text-sm font-medium'>Habigonj</p>
                        </div>
                        :
                        <div className=' bg-black bg-opacity-25 w-full px-1'>
                            {/* <p className=' text-lg font-semibold text-white'>The Place Luxury Resort</p> */}
                            <p className=' text-white font-medium'>Habigonj</p>
                        </div>
                }
            </div>
        </div>

    )
}
