import React from 'react'
import bg from "../../Photos/BG.png"

function SegmentDetails({ dacShowMod }) {
    return (
        <div className={` w-full overflow-y-hidden transition-all ease-in-out duration-500 ${dacShowMod ? " max-h-[300px]" : " max-h-0"} `}>
            <span className=' block w-full h-[1px] bg-gray-300 my-2 '></span>
            <div className='px-5 py-1 flex justify-between'>
                <div className=' flex items-center'>
                    <div>
                        <img src={bg} alt="" className=' w-14' />
                    </div>
                    <div className=' ml-3'>
                        <p className=' text-[12px] sm:text-sm text-primary'>US Bangla</p>
                        <p className=' text-[12px] sm:text-sm text-gray-600'>BG | 615</p>
                    </div>
                </div>
                <div>
                    <p className=' text-gray-500'>(Economy)</p>
                </div>
            </div>
            <span className=' block w-full h-[1px] bg-gray-300 my-2 '></span>
            <div className=' px-5 pb-3 flex justify-between items-center flex-wrap'>
                <div className=' w-full sm:w-auto'>
                    <p className=' mt-2 text-lg font-bold text-primary'>15:45</p>
                    <p className=' text-gray-500 text-sm'>Wed, 16 Nov 2022</p>
                    <p className='text-sm text-gray-500'>DAC</p>
                </div>

                <div className=' flex flex-col items-center pr-2 w-fulll sm:w-auto sm:my-0 my-3'>
                    <p className=' text-[12px] text-gray-500 mt-1'>No stop</p>
                    <div className=' relative w-20 sm:w-32 h-[1px] bg-primary mt-3'>
                        <span className=' absolute w-3 h-[1px] inline-block bg-primary -top-1 -right-[2px] rotate-45'></span>
                    </div>
                    <p className=' text-gray-400 text-sm font-bold mt-3'>45 minutes</p>
                </div>

                <div className=' w-full sm:w-auto text-right'>
                    <p className=' mt-2 text-lg font-bold text-primary'>15:45</p>
                    <p className=' text-gray-500 text-sm'>Wed, 16 Nov 2022</p>
                    <p className='text-sm text-gray-500'>CXB</p>
                </div>
            </div>
        </div>
    )
}

export default SegmentDetails