import SearchComponent from "../../Components/Search/SearchComponent"
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import TourBookComponent from "../../Components/TourBook/TourBookComponent";
import TourFilterComponent from "../../Components/TourFilters/TourFilterComponent";
import attructionPhoto from "../../Photos/tour/attractions.svg"
import activitiesPhoto from "../../Photos/tour/activities.svg"
import adventurePhoto from "../../Photos/tour/adventure.svg"
import dayoutPhoto from "../../Photos/tour/dayout.svg"
import sighseeingPhoto from "../../Photos/tour/sightseeing.svg"
import { useState } from "react";

function TourList() {
    const [showFilterComponent, setShowFilterComponent] = useState(false)

    return (
        <div className="w-full pt-12 lg:pt-20">
            <SearchComponent />
            <div className='  w-full xl:w-[1160px] mx-auto px-5'>
                <div className="grid grid-cols-4 gap-5 my-4">
                    <div className=" col-span-4 md:col-span-1">
                        <TourFilterComponent showFilterComponent={showFilterComponent} setShowFilterComponent={setShowFilterComponent} />
                    </div>
                    <div className=' col-span-4 md:col-span-3 '>
                        <div className=" mb-3 grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-3">
                            <div className=" bg-white shadow rounded p-3 flex gap-2">
                                <p className=" text-[12px] text-primary font-medium">Attructions & Shows</p>
                                <img src={attructionPhoto} alt="" className=" w-10" />
                            </div>
                            <div className=" bg-white shadow rounded p-3 flex gap-2">
                                <p className=" text-[12px] text-primary font-medium">Activities & Experience</p>
                                <img src={activitiesPhoto} alt="" className=" w-10" />
                            </div>
                            <div className=" bg-white shadow rounded p-3 flex gap-2">
                                <p className=" text-[12px] text-primary font-medium">Sightseeing & Days-Toures</p>
                                <img src={sighseeingPhoto} alt="" className=" w-8" />
                            </div>
                            <div className=" bg-white shadow rounded p-3 flex gap-2">
                                <p className=" text-[12px] text-primary font-medium">Day-Out Packedges</p>
                                <img src={dayoutPhoto} alt="" className=" w-9" />
                            </div>
                            <div className=" bg-white shadow rounded p-3 flex justify-between items-center">
                                <p className=" text-[12px] text-primary font-medium">Adventure</p>
                                <img src={adventurePhoto} alt="" className=" w-8" />
                            </div>
                        </div>
                        <TourBookComponent />
                        <TourBookComponent />
                        <TourBookComponent />
                        <TourBookComponent />
                        <TourBookComponent />
                        <TourBookComponent />
                        <TourBookComponent />
                        <div className=" flex justify-center h-10">
                            <div className=" flex items-center gap-2">
                                <div className=" w-8 bg-white rounded h-8 flex justify-center items-center border border-primary hover:bg-gray-200 cursor-pointer">
                                    <AiOutlineLeft className=" text-sm" />
                                </div>
                                <div className=" w-8 bg-primary rounded h-8 flex justify-center items-center border border-primary  cursor-pointer text-white">
                                    <span>1</span>
                                </div>
                                <div className=" w-8 bg-white rounded h-8 flex justify-center items-center border border-primary hover:bg-gray-200 cursor-pointer text-primary">
                                    <span>2</span>
                                </div>
                                <div className=" w-8 bg-white rounded h-8 flex justify-center items-center border border-primary hover:bg-gray-200 cursor-pointer text-primary">
                                    <span>2</span>
                                </div>
                                <div className=" w-8 bg-white rounded h-8 flex justify-center items-center border border-primary hover:bg-gray-200 cursor-pointer">
                                    <AiOutlineRight className=" text-sm" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <button onClick={() => setShowFilterComponent(true)} className=" bg-yellow-400 py-1 px-2 hover:shadow-lg text-primary font-bold rounded-md fixed bottom-10 left-1/2 -translate-x-1/2 md:hidden">Flilter</button>
        </div>

    )
}

export default TourList