import React from 'react'
import { MdOutlineAccessTimeFilled } from "react-icons/md"

function TimeoutComponent() {
    return (
        <div className=' w-full p-3 bg-white rounded'>
            <div className=' p-3 rounded bg-gray-100 flex gap-3'>
                <div>
                    <MdOutlineAccessTimeFilled className=' text-2xl text-gray-600' />
                </div>
                <div className=' flex gap-2'>
                    <div>
                        <p className=' text-xl text-primary font-bold'>15: </p>
                        <p className=' text-gray-600 text-sm'>min</p>
                    </div>
                    <div>
                        <p className=' text-xl text-primary font-bold'>42</p>
                        <p className=' text-gray-600 text-sm'>sec</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TimeoutComponent