
import SearchComponent from "../../Components/Search/SearchComponent"
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import HotelBookComponent from "../../Components/HotelBook/HotelBookComponent";
import HotelFilterComponent from "../../Components/HotelFilters/HotelFilterComponent";
import { FaTag } from "react-icons/fa";
import { useState } from "react";

function HotelList() {
    const [showFilterComponent, setShowFilterComponent] = useState(false)

    return (
        <div className="w-full pt-12 lg:pt-20">
            <SearchComponent />
            <div className='  w-full xl:w-[1160px] mx-auto px-5'>
                <div className="grid grid-cols-4 gap-5 my-5">
                    <div className=" col-span-4 md:col-span-1">
                        <HotelFilterComponent showFilterComponent={showFilterComponent} setShowFilterComponent={setShowFilterComponent} />
                    </div>
                    <div className=' col-span-4 md:col-span-3'>
                        <div className=" p-5 bg-white mb-5 shadow rounded-md grid grid-cols-7 gap-3">
                            <div className="col-span-1">
                                <div className=" w-8 h-8 md:w-12 md:h-12  bg-primary flex items-center justify-center rounded-full ">
                                    <FaTag className=" text-white  md:text-xl" />
                                </div>
                            </div>
                            <div className=" col-span-6">
                                <p className=" text-primary text-sm font-semibold">You could be seeing lower prices</p>
                                <p className=" text-sm text-gray-500">With member prices, you could save more right now on thousands of properties.</p>
                                <div className=" flex gap-3 ">
                                    <p className=" text-primary font-semibold text-sm">Sign in</p>
                                    <p className=" text-primary font-semibold text-sm">Sign up</p>
                                </div>
                            </div>
                        </div>
                        <HotelBookComponent />
                        <HotelBookComponent />
                        <HotelBookComponent />
                        <HotelBookComponent />
                        <HotelBookComponent />
                        <HotelBookComponent />
                        <HotelBookComponent />
                        <div className=" flex justify-center h-10">
                            <div className=" flex items-center gap-2">
                                <div className=" w-8 bg-white rounded h-8 flex justify-center items-center border border-primary hover:bg-gray-200 cursor-pointer">
                                    <AiOutlineLeft className=" text-sm" />
                                </div>
                                <div className=" w-8 bg-primary rounded h-8 flex justify-center items-center border border-primary  cursor-pointer text-white">
                                    <span>1</span>
                                </div>
                                <div className=" w-8 bg-white rounded h-8 flex justify-center items-center border border-primary hover:bg-gray-200 cursor-pointer text-primary">
                                    <span>2</span>
                                </div>
                                <div className=" w-8 bg-white rounded h-8 flex justify-center items-center border border-primary hover:bg-gray-200 cursor-pointer text-primary">
                                    <span>2</span>
                                </div>
                                <div className=" w-8 bg-white rounded h-8 flex justify-center items-center border border-primary hover:bg-gray-200 cursor-pointer">
                                    <AiOutlineRight className=" text-sm" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <button onClick={() => setShowFilterComponent(true)} className=" bg-yellow-400 py-1 px-2 hover:shadow-lg text-primary font-bold rounded-md fixed bottom-10 left-1/2 -translate-x-1/2 md:hidden">Flilter</button>
        </div>

    )
}

export default HotelList