import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { AiFillCloseCircle } from 'react-icons/ai';
import { useLocation } from 'react-router-dom';
import getDate from '../../../utils/getDate';
import BookingClass from './BookingClass';
import CalenderComponent from './CalenderComponent';
import SearchFields from './SearchFields';

export default function MultiCityFlightItem({ item, multicityData, setMulticityData, index, travelers, setTravelers, ticketClass, setTicketClass, totalTraveler, decreseMulticityData, increaseMultiCityData, initialData, searchFlight }) {

    const [searchOption, setSearchOption] = useState("");
    const [clickedSearchOption, setClickedSearchOption] = useState(false);
    const [clickedDateOption, setClickedDateOption] = useState(false);
    const [clickedClassOption, setClickedClassOption] = useState(false);
    const location = useLocation()

    useEffect(() => {
        if (location.search && location.search.split("?")[1].split("&")[0].split("=")[1] !== "multi-city" || !location.search) {
            const newmulticityData = [...multicityData]
            newmulticityData[index].depatureData = getDate(new Date())
            setMulticityData(newmulticityData)
        }
    }, [location])

    const toggleSearchOption = (option, e) => {
        const closeReturnFlight = document.getElementById("close-return-flight");
        if (e.target.className.includes("search-item") || e.target === closeReturnFlight) {
            setClickedSearchOption(false)
            setSearchOption("")
        } else {
            setSearchOption(option);
            setClickedSearchOption(true);
        }
    }

    const changeFlightData = (data, option) => {
        const newmulticityData = [...multicityData]
        if (option === "from") {
            newmulticityData[index].flightFrom = data
        } else {
            newmulticityData[index].flightTo = data
        }
        setMulticityData(newmulticityData)
    }
    const changeDateOption = (data, option) => {
        const newmulticityData = [...multicityData]
        newmulticityData[index].depatureData = data
        setMulticityData(newmulticityData)
        setClickedDateOption(false)
    }

    const showCalander = () => {
        const calander = document.getElementsByClassName("calander")[index]
        // console.log(calander[index])
        calander.showPicker()
        setClickedDateOption(true)
    }

    const toggleClassOption = (e) => {
        if (e.target.id === "Close") {
            setClickedClassOption(false)
        } else {
            setClickedClassOption(true)
        }
    }

    document.addEventListener('mouseup', function (e) {
        if (clickedSearchOption) {
            let searchFields = document.getElementById("search-fields")
            if (!searchFields?.contains(e.target)) {
                setClickedSearchOption(false)
            }
        }
        if (clickedDateOption) {
            let calander = document.getElementById("calander")
            if (!calander?.contains(e.target)) {
                setClickedDateOption(false)
            }
        }
        if (clickedClassOption) {
            let bookingClass = document.getElementById("bookingClass")
            if (!bookingClass?.contains(e.target)) {
                setClickedClassOption(false)
            }
        }
    });

    return (
        <div className={`${location.pathname === "/" ? "w-full" : " grid grid-cols-12 gap-2"}`}>
            <div className={`grid grid-cols-1 md:grid-cols-4 gap-2 relative ${location.pathname === "/" ? "" : " col-span-12 md:col-span-10"}`}>
                <div onClick={(e) => { toggleSearchOption("from", e) }} className={` relative border w-full h-full rounded-xl p-2 px-3 text-left cursor-pointer ${searchOption === "from" && clickedSearchOption && " bg-gray-100"}`}>
                    <p className=" text-primary text-[10px] md:text-[12px]">FROM</p>
                    <p className=' font-bold text-primary md:text-base text-sm'>{item.flightFrom?.city ? item.flightFrom.city : "Select a city"}</p>
                    <p className='text-primary text-[10px] md:text-[12px]'>{item.flightFrom?.name ? item.flightFrom.name : "Click to choose an airport"}</p>
                    {
                        clickedSearchOption && searchOption === "from" &&
                        <SearchFields initialData={initialData} flightFrom={item.flightFrom} flightTo={item.flightTo} changeFlightData={changeFlightData} option="from" />
                    }
                </div>
                <div onClick={(e) => { toggleSearchOption("to", e) }} className={` border rounded-xl p-2 px-3 text-left cursor-pointer relative ${searchOption === "to" && clickedSearchOption && " bg-gray-100"}`}>
                    <p className=" text-primary text-[10px] md:text-[12px]">TO</p>
                    <p className='  font-bold text-primary md:text-base text-sm'>{item.flightTo?.city ? item.flightTo.city : "Select a city"}</p>
                    <p className='text-primary text-[10px] md:text-[12px]'>{item.flightTo?.name ? item.flightTo.name : "Click to choose an airport"}</p>
                    {
                        clickedSearchOption && searchOption === "to" &&
                        <SearchFields initialData={initialData} flightFrom={item.flightFrom} flightTo={item.flightTo} changeFlightData={changeFlightData} option="to" />
                    }
                </div>
                <div onClick={(e) => { showCalander(); toggleSearchOption("depature", e) }} className={` border rounded-lg text-left p-3 relative cursor-pointer ${searchOption === "depature" && clickedDateOption && "bg-gray-100"}`}>
                    <p className=" text-primary text-[10px] md:text-[12px]">JOURNEY DATE</p>
                    <p className=' font-bold text-primary text-sm md:text-base'>{item.depatureData?.date ? item.depatureData.date : "Pick a date"}</p>
                    <p className=' text-primary text-[10px] md:text-[12px]'>{item.depatureData?.day && item.depatureData.day}</p>
                    <CalenderComponent option="Depature" setClickedDateOption={setClickedDateOption} changeDateOption={changeDateOption} />
                </div>
                {
                    index === 0 ?
                        <div onClick={(e) => { toggleClassOption(e); toggleSearchOption("traveler", e) }} className={` border rounded-lg p-2 text-left cursor-pointer relative ${searchOption === "traveler" && clickedClassOption && "bg-gray-100"}`}>
                            <p className="  text-primary text-[10px] md:text-[12px]">Traveler, Class</p>
                            <p className='  font-bold text-base md:text-lg text-primary'>{totalTraveler} Traveler</p>
                            <p className=' ttext-primary text-[10px] md:text-[12px]'>Economy</p>
                            {
                                clickedClassOption &&
                                <BookingClass ticketClass={ticketClass} setTicketClass={setTicketClass} travelers={travelers} setTravelers={setTravelers} />
                            }
                        </div>
                        :
                        <div className=' py-2 border rounded-lg grid grid-cols-3 '>
                            {
                                index === multicityData.length - 1 &&
                                <>
                                    <div className=' flex items-center justify-center col-span-2 border-r cursor-pointer' onClick={increaseMultiCityData}>
                                        <p className=' text-primary text-[12px] font-semibold'>Add Another City</p>
                                    </div>
                                    <div className=' flex items-center justify-center clear-left cursor-pointer col-span-1' onClick={() => decreseMulticityData(index)}>
                                        <AiFillCloseCircle className=' text-2xl text-gray-500 ' />
                                    </div>
                                </>
                            }
                        </div>
                }
            </div>
            {
                index === multicityData.length - 1 &&
                <div className={`${location.pathname === "/" ? "hidden" : "block col-span-12 md:col-span-2"}`}>
                    <button onClick={searchFlight} className={` w-full h-full shadow py-[10px] text-center bg-yellow-400text-sm sm:text-base md:text-lg text-primary font-semibold rounded-md`}>Modify</button>
                </div>
            }
        </div>
    )
}
