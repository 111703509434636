import React from 'react'
import { AiOutlineMinus } from 'react-icons/ai'
import { BsPlusLg } from "react-icons/bs"

function BookingClass({ travelers, setTravelers, ticketClass, setTicketClass }) {
    const incrementTravelers = (option) => {
        if (option === "adults") {
            setTravelers({ ...travelers, adults: travelers.adults + 1 })
        } else if (option === "children") {
            setTravelers({ ...travelers, children: travelers.children + 1 })
        } else if (option === "infant") {
            setTravelers({ ...travelers, infant: travelers.infant + 1 })
        }
    }
    const dicrimentTravelers = (option) => {
        if (option === "adults") {
            if (travelers.adults >= 1) {
                setTravelers({ ...travelers, adults: travelers.adults - 1 })
            }
        } else if (option === "children") {
            if (travelers.children >= 1) {
                setTravelers({ ...travelers, children: travelers.children - 1 })
            }
        } else if (option === "infant") {
            if (travelers.infant >= 1) {
                setTravelers({ ...travelers, infant: travelers.infant - 1 })
            }
        }
    }




    return (
        <div className=' z-10 absolute top-full left-0  p-2 w-56 sm:w-72 bg-white shadow-lg rounded-md shadow-gray-600' id='bookingClass'>
            {/* <p className=' text-primary text-sm font-medium'>Travelers</p> */}
            <div className=' mb-1 border-b border-gray-300 py-2'>
                <div className=' flex items-center justify-between '>
                    <p className=' text-sm text-primary'>Adults</p>
                    <div className=' flex items-center '>
                        <div onClick={() => dicrimentTravelers("adults")} className='flex justify-center items-center h-5 w-5 rounded-full border border-primary mr-3'>
                            <AiOutlineMinus className=' text-primary text-[10px]' />
                        </div>
                        <span className=''>{travelers.adults}</span>
                        <div onClick={() => incrementTravelers("adults")} className='flex justify-center items-center h-5 w-5 rounded-full border border-primary ml-3'>
                            <BsPlusLg className=' text-primary text-[10px]' />
                        </div>
                    </div>
                </div>
                <p className=' text-[10px] text-primary -mt-[2px]'>12 years and above</p>
            </div>
            <div className=' mb-1 border-b border-gray-300 py-2'>
                <div className=' flex items-center justify-between '>
                    <p className=' text-sm text-primary'>Children</p>
                    <div className=' flex items-center '>
                        <div onClick={() => dicrimentTravelers("children")} className='flex justify-center items-center h-5 w-5 rounded-full border border-primary mr-3'>
                            <AiOutlineMinus className=' text-primary text-[10px]' />
                        </div>
                        <span className=''>{travelers.children}</span>
                        <div onClick={() => incrementTravelers("children")} className='flex justify-center items-center h-5 w-5 rounded-full border border-primary ml-3'>
                            <BsPlusLg className=' text-primary text-[10px]' />
                        </div>
                    </div>
                </div>
                <p className=' text-[10px] text-primary -mt-[2px]'>12 years and above</p>
            </div>
            <div className=' mb-1 border-b border-gray-300 py-2'>
                <div className=' flex items-center justify-between '>
                    <p className=' text-sm text-primary'>Infant</p>
                    <div className=' flex items-center '>
                        <div onClick={() => dicrimentTravelers("infant")} className='flex justify-center items-center h-5 w-5 rounded-full border border-primary mr-3'>
                            <AiOutlineMinus className=' text-primary text-[10px]' />
                        </div>
                        <span className=''>{travelers.infant}</span>
                        <div onClick={() => incrementTravelers("infant")} className='flex justify-center items-center h-5 w-5 rounded-full border border-primary ml-3'>
                            <BsPlusLg className=' text-primary text-[10px]' />
                        </div>
                    </div>
                </div>
                <p className=' text-[10px] text-primary -mt-[2px]'>12 years and above</p>
            </div>
            <div className=' w-full'>
                <p className='text-sm'>Class</p>
                <div className='  flex items-center gap-3'>
                    <div className=' flex items-center gap-1'>
                        <input type="radio" name="" id="economy" value={ticketClass} onChange={(e) => setTicketClass("economy")} checked={ticketClass === "economy"} />
                        <label htmlFor="economy" className={`cursor-pointer ${ticketClass === "economy" ? "text-primary" : "text-gray-300"}  text-[12px]`}>Economy</label>
                    </div>
                    <div className=' flex items-center gap-1'>
                        <input type="radio" name="" id="business" value={ticketClass} onChange={(e) => setTicketClass("business")} checked={ticketClass === "business"} />
                        <label htmlFor="business" className={`cursor-pointer ${ticketClass === "business" ? "text-primary" : "text-gray-300"} text-[12px] `}>Round Way</label>
                    </div>
                </div>
                <div className=' w-full flex justify-end'>
                    <button id='Close' className=' bg-yellow-400 font-semibold text-primary py-1 px-3 rounded hover:shadow-xl'>Done</button>
                </div>
            </div>
        </div>
    )
}

export default BookingClass