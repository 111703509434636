import React from 'react'
import bg from "../../Photos/BG.png"

function FlightSegment() {

    return (
        <div className=' p-1 sm:p-3'>
            <div className=' w-full flex justify-between'>
                <div className=' flex items-center'>
                    <div>
                        <img src={bg} alt="" className=' w-14' />
                    </div>
                    <div className=' ml-2'>
                        <p className=' text-primary text-sm'>Qatar Airways</p>
                        <p className=' text-gray-500 text-sm'>QR 635 | Airbus 333</p>
                    </div>
                </div>
                <div>
                    <p className=' text-gray-500 text-sm'>(Economy)</p>
                </div>
            </div>

            <span className=' w-full h-[1px] bg-secoundery block mt-2'></span>

            <div className=' flex justify-between flex-wrap mt-5'>
                <div className=' w-full sm:w-auto'>
                    <p className=' font-semibold text-lg text-primary'>13: 40</p>
                    <p className=' text-sm text-gray-500'>Fri, 25 Nov</p>
                    <p className=' text-sm text-gray-500'>2022</p>
                    <p className=' text-sm text-gray-500'>DAC</p>
                </div>
                <div className=' flex flex-col justify-start items-start sm:items-center'>
                    <div className=' relative w-20  h-[1px] bg-primary mt-2'>
                        <span className=' absolute w-3 h-[1px] inline-block bg-primary -top-1 -right-[2px] rotate-45'></span>
                    </div>
                    <p className=' text-primary font-semibold mt-2'>6h</p>
                </div>
                <div className=' w-full sm:w-auto text-right'>
                    <p className=' font-semibold text-lg text-primary'>13: 40</p>
                    <p className=' text-sm text-gray-500'>Fri, 25 Nov</p>
                    <p className=' text-sm text-gray-500'>2022</p>
                    <p className=' text-sm text-gray-500'>DOH</p>
                </div>
            </div>
        </div>
    )
}

export default FlightSegment