import React from 'react'
import { useState } from 'react'

export default function ChipestOrFastest() {

    const [filterType, setFilterType] = useState("chipest");
    const toggleFilterType = (type) => {
        setFilterType(type)
    }

    return (
        <div className=' bg-white p-1 sm:p-3 rounded-md shadow grid grid-cols-2'>
            <div className=' border-r-2 border-gray-500 pr-3'>
                <div className={`${filterType === "chipest" && " bg-gray-100"} rounded py-1 px-2 cursor-pointer hover:bg-gray-100`} onClick={() => toggleFilterType("chipest")}>
                    <p className=' text-primary font-semibold text-sm sm:text-base'>Chepest</p>
                    <p className=' text-primary text-[12px] sm:block hidden'>Showing the cheapest flights in ascending order</p>
                </div>
            </div>
            <div className='pl-3'>
                <div className={`${filterType === "fastest" && " bg-gray-100"} rounded py-1 px-2 cursor-pointer hover:bg-gray-100`} onClick={() => toggleFilterType("fastest")}>
                    <p className=' text-primary font-semibold text-sm sm:text-base'>Fastest</p>
                    <p className=' text-primary text-[12px] sm:block hidden'>Click to see the fastest flights in ascending order</p>
                </div>
            </div>
        </div>
    )
}
