import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { AiOutlineSearch } from 'react-icons/ai'
import { MdOutlineModeEditOutline } from 'react-icons/md'
import { useLocation } from 'react-router-dom'

export default function TravelSearchComponent({ traveleLocations, toureData, setToureData, showSerchComponent, setShowSearchComponent }) {

    const [locations, setLocations] = useState([])
    const [showList, setShowList] = useState(false)
    const location = useLocation()

    const changeFlightDatas = (value) => {
        value.toLowerCase()
        if (value) {
            let location = traveleLocations.filter((location) => location.name.toLowerCase().search(value) > -1)
            setLocations(location)
        } else {
            setLocations(traveleLocations.slice(0, 9))
        }
    }

    document.addEventListener("mouseup", (e) => {
        const wraper = document.getElementById("travel");
        if (showList) {
            if (!wraper.contains(e.target)) {
                setShowList(false)
            }
        }
    })

    useEffect(() => {
        if (traveleLocations) {
            setLocations(traveleLocations)
        }
    }, [traveleLocations])

    const changeFlightData = (id) => {
        const location = traveleLocations.find((loc) => loc.id === id)
        setToureData(location)
        setShowList(false)
    }


    console.log(toureData)

    return (
        <>
            <div className={` pt-5 ${showSerchComponent ? "block" : "hidden"}`} >
                <div className=' grid grid-cols-6 gap-3'>
                    <div className=' col-span-6 md:col-span-5 my-1 md:my-2 border rounded-lg p-2 md:p-3 cursor-pointer' onClick={() => setShowList(true)}>
                        <p className=' text-gray-500 text-[12px] md:text-sm'>LOCATION/TOUR</p>
                        <p className=' text-base md:text-lg font-bold text-primary'>{toureData.name}</p>
                    </div>
                    <div className='col-span-6 md:col-span-1 flex justify-center items-center'>
                        <div className={`${location.pathname === "/" ? "hidden" : "block w-full"} `}>
                            <button className={` w-full h-full shadow py-4 text-center bg-yellow-400 text-sm sm:text-base md:text-lg leading-5 text-primary font-bold rounded-md`}>
                                <p>Modify Search</p>
                            </button>
                        </div>
                    </div>
                </div>

                {
                    showList &&
                    <div className=' z-10 absolute top-full left-0 h-96 w-full bg-white shadow-lg rounded overflow-y-scroll scrollbar' id='travel'>
                        <div className=' flex items-center p-2 sticky bg-white shadow-sm top-0 left-0'>
                            <AiOutlineSearch className=' mr-1 text-red-500' />
                            <input id='input' type="text" autoFocus placeholder='Type name of location' className=' py-1 pl-2 focus:outline-none placeholder:text-[12px] w-full' onChange={(e) => { changeFlightDatas(e.target.value) }} />
                        </div>
                        <div className=' mt-1'>
                            {
                                locations.map((location, index) =>
                                    <div onClick={() => { changeFlightData(location.id) }} className=' search-item flex justify-between mb-2 hover:bg-gray-100 px-2 cursor-pointer' key={index}>
                                        <p className=' text-[11px] md:text-[13px] font-medium search-item p-2'>{location.name}</p>
                                    </div>
                                )
                            }
                        </div>
                    </div >
                }
            </div>
            {
                location.pathname !== "/" && !showSerchComponent &&
                <div className=' w-full flex justify-between items-center md:hidden'>
                    <div>
                        <p className=' text-primary text-sm font-semibold'>{toureData.name}</p>
                        {/* <p className=' text-[12px] text-gray-500'>From <span className=' font-semibold'>{toureData.checkinData.date}</span> To <span className=' font-semibold'>{toureData.checkoutData.date}</span></p> */}
                    </div>
                    <div className=' flex items-center gap-1 bg-yellow-400 py-1 px-2  rounded cursor-pointer' onClick={() => setShowSearchComponent(true)}>
                        <MdOutlineModeEditOutline className=' text-gray-800 text-sm' />
                        <button className=' font-semibold'>Edit</button>
                    </div>
                </div>
            }
        </>

    )
}
