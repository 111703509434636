import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    SearchType: "flight",
}

export const SearchType = createSlice({
    name: 'SearchType',
    initialState,
    reducers: {
        changeSearchType: (state, action) => {
            state.SearchType = action.payload
        },
    },
})

export const { changeSearchType } = SearchType.actions

export default SearchType.reducer