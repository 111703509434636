
import FilterSegmentLength from "./FilterSegmentLength";
import FilterSelectItem from "./FilterSelectItem";
import FilterTimeComponent from "./FilterTimeComponent";
import RangeComponent from "./RangeComponent"
import { useState } from "react";
import { IoIosArrowDown } from "react-icons/io"
import { FaFilter } from "react-icons/fa"
import { AiOutlineClose } from "react-icons/ai";
import { useEffect } from "react";


function FilterComponent({ showFilterComponent, setShowFilterComponent }) {
    const [enabled, setEnabled] = useState(false);
    const [colapsed, setcolapsed] = useState(false)
    const [showFilter, setShowFilter] = useState(true)

    const toggleColaps = () => {
        setcolapsed(!colapsed)
    }

    // resize 
    window.addEventListener("resize", (e) => {
        if (window.innerWidth >= 768) {
            if (!showFilter) {
                setShowFilter(true)
            }
        } else {
            setShowFilter(false)
        }
    })

    // initial load
    useEffect(() => {
        if (window.innerWidth >= 768) {
            setShowFilter(true)
        } else {
            setShowFilter(false)
        }
    }, [])

    useEffect(() => {
        if (showFilterComponent) {
            console.log(showFilterComponent)
            setShowFilter(true)
            setcolapsed(true)
        }
    }, [showFilterComponent])


    return (
        <div className={`${showFilter ? "flex fixed top-12 z-[10] md:z-0 left-0 md:static h-screen md:h-auto w-screen md:w-full xl:w-[1160px] justify-center md:px-5 mx-auto md:mt-5" : "hidden"} `}>
            <div className=" w-full bg-white">

                {
                    colapsed ?
                        <div className=" flex justify-between py-3 px-5 shadow">
                            <p className=" text-primary md:text-base text-sm font-semibold">Showing 10 of 74 flights</p>
                            <div className=" hidden md:flex items-center gap-3">
                                <button className=" py-[2px] px-2 text-[12px] md:text-sm border border-primary rounded hover:bg-gray-200" onClick={toggleColaps}>Close</button>
                                <p className=" text-[12px] md:text-sm text-primary">Reset All Filters</p>
                            </div>
                            <div className={`md:hidden`}>
                                <AiOutlineClose onClick={() => { setcolapsed(false); setShowFilter(false); setShowFilterComponent(false) }} className=' text-gray-500 cursor-pointer text-lg' />
                            </div>
                        </div>
                        :
                        <div className=" flex justify-between gap-5 flex-wrap py-3 px-5 shadow">
                            <div className=" flex items-center gap-1">
                                <FaFilter className=" text-[12px] md:text-sm text-gray-600" />
                                <p className=" text-primary font-semibold">Filters</p>
                            </div>
                            <div className=" flex items-center ">
                                <span className=" text-primary font-bold mr-1">Stops</span>
                                <div className='text-gray-500 flex gap-[2px] items-center'>
                                    <FilterSegmentLength l={1} />
                                    <FilterSegmentLength l={2} />
                                    <FilterSegmentLength l={3} />
                                    <FilterSegmentLength l={4} />
                                </div>
                            </div>
                            <div>
                                <div className="flex" >
                                    <label class="inline-flex relative items-center cursor-pointer">
                                        <input
                                            type="checkbox"
                                            className="sr-only peer"
                                            checked={enabled}
                                            readOnly
                                        />
                                        <div
                                            onClick={() => {
                                                setEnabled(!enabled);
                                            }}
                                            className="w-8 h-[14px] bg-gray-200 rounded-full peer  peer-focus:ring-green-300  peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-1 after:left-0 after:bg-white after:border-gray-300 after:border after:rounded-full after:h-3 after:w-4 after:transition-all peer-checked:bg-primary"
                                        ></div>
                                    </label>
                                    <span onClick={() => setEnabled(!enabled)} className="ml-2 text-[12px] md:text-sm text-gray-500 cursor-pointer">Partial Refundable</span>
                                </div>
                            </div>
                            <div className=" flex items-center cursor-pointer" onClick={toggleColaps}>
                                <p className=" text-[12px] md:text-sm text-gray-700">Layover Time</p>
                                <IoIosArrowDown className=" text-[12px] md:text-sm text-gray-700" />
                            </div>
                            <div className=" flex items-center cursor-pointer" onClick={toggleColaps}>
                                <p className=" text-[12px] md:text-sm text-gray-700">Airlines</p>
                                <IoIosArrowDown className=" text-[12px] md:text-sm text-gray-700" />
                            </div>
                            <div className=" flex items-center cursor-pointer" onClick={toggleColaps}>
                                <p className=" text-[12px] md:text-sm text-gray-700">More Filters</p>
                                <IoIosArrowDown className=" text-[12px] md:text-sm text-gray-700" />
                            </div>
                        </div>
                }


                <div className={` ${colapsed ? " max-h-screen py-2 overflow-y-scroll scrollbar" : "max-h-0"} w-full flex justify-between flex-wrap transition-all duration-150 ease-linear overflow-hidden`}>
                    <div className=" w-[180px]">
                        <div className=' pl-5 pr-2 py-2'>
                            <p className=' font-medium text-primary'>Stops</p>
                            <div className=' mt-3 text-gray-500 flex gap-[2px] items-center'>
                                <FilterSegmentLength l={1} />
                                <FilterSegmentLength l={2} />
                                <FilterSegmentLength l={3} />
                                <FilterSegmentLength l={4} />
                            </div>
                        </div>
                        <div className="pl-5 pr-2 pt-3">
                            <p className=" text-primary font-medium pb-1">Fare Type:</p>
                            <div className="flex " >
                                <label class="inline-flex relative items-center cursor-pointer">
                                    <input
                                        type="checkbox"
                                        className="sr-only peer"
                                        checked={enabled}
                                        readOnly
                                    />
                                    <div
                                        onClick={() => {
                                            setEnabled(!enabled);
                                        }}
                                        className="w-6 h-[12px] bg-gray-200 rounded-full peer  peer-focus:ring-green-300  peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-1 after:left-0 after:bg-white after:border-gray-300 after:border after:rounded-full after:h-[10px] after:w-[11px] after:transition-all peer-checked:bg-primary"
                                    ></div>
                                </label>
                                <span onClick={() => setEnabled(!enabled)} className="ml-2 text-[12px] text-gray-500 cursor-pointer">Partial Refundable</span>
                            </div>
                        </div>
                        <div className="pl-5 pr-2 py-3">
                            <RangeComponent />
                        </div>
                    </div>

                    <div className=' px-5 py-2'>
                        <p className=' font-medium pb-3 text-primary'>Schedule</p>
                        <FilterTimeComponent title="Onward Depart Time" />
                        <FilterTimeComponent title="Return Depart Time" />
                        <FilterTimeComponent title="Layover Time" />
                    </div>


                    <div className=' px-5 py-2'>
                        <p className=' font-medium text-primary '>Airlines</p>
                        <div className=' flex justify-between mt-2 text-gray-500'>
                            <FilterSelectItem title="Biman Bangladesh Airlines" price="9,599" />
                        </div>
                        <div className=' flex justify-between mt-2 text-gray-500'>
                            <FilterSelectItem title="Air Astra" price="9,600" />
                        </div>
                        <div className=' flex justify-between mt-2 text-gray-500'>
                            <FilterSelectItem title="US Bangla" price="10,196" />
                        </div>
                    </div>

                    <div className=' px-5 py-2 max-w-[250px]'>
                        <p className=' font-medium text-primary'>Layover Airport</p>
                        <div className=' flex justify-between mt-2 text-gray-500'>
                            <FilterSelectItem title="Osmany International Airport (ZYL)" />
                        </div>
                    </div>
                    <div className=' px-5 py-2'>
                        <p className=' font-medium text-primary'>Aircraft</p>
                        <div className='mt-2 text-gray-500'>
                            <FilterSelectItem title="738" />
                        </div>
                        <div className='mt-2 text-gray-500'>
                            <FilterSelectItem title="AT7" />
                        </div>
                        <div className='mt-2 text-gray-500'>
                            <FilterSelectItem title="DH8" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FilterComponent