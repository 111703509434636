import React from 'react'
import FilterSelectItem from '../FlightFilters/FilterSelectItem'

export default function TagList({ tags }) {
    return (
        <div>
            {
                tags.map((f, i) => {
                    return (
                        <div className='mt-3 text-gray-500' key={i}>
                            <FilterSelectItem title={f} />
                        </div>
                    )
                })
            }
        </div>
    )
}
