import React, { useState } from 'react'
import { Range } from 'react-range';

function RangeComponent() {

    const [rengeValues, setRangeValues] = useState([500])
    const fixedRange = 500
    return (
        <div className=''>
            <p className=' font-medium text-primary'>Price Range</p>
            <div className=' mt-2 mb-3'>
                <Range
                    step={0.1}
                    min={0}
                    max={1000}
                    values={rengeValues}
                    onChange={(values) => setRangeValues(values)}
                    renderTrack={({ props, children }) => (
                        <div
                            {...props}
                            style={{
                                ...props.style,
                                height: '5px',
                                width: '100%',
                                backgroundColor: 'rgb(241 245 249)'
                            }}
                        >
                            {children}
                        </div>
                    )}
                    renderThumb={({ props }) => (
                        <div
                            {...props}
                            style={{
                                ...props.style,
                                height: '16px',
                                width: '16px',
                                borderRadius: '50%',
                                backgroundColor: '#1c3c6b',
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                outline: "none"
                            }}

                        >
                            <span className=' bg-white h-[12px] w-[12px] rounded-full'></span>
                        </div>
                    )}
                />
            </div>
            <div className=' flex justify-between'>
                <span className=' text-[12px] text-primary font-semibold'>BDT {fixedRange}</span>
                <span className=' text-[12px] text-primary font-semibold'>BDT {rengeValues[0]}</span>
            </div>
            <div className=' flex justify-between'>
                <span className=' text-[12px] text-primary font-semibold'>Min</span>
                <span className=' text-[12px] text-primary font-semibold'>Max</span>
            </div>
        </div>
    )
}

export default RangeComponent