import React from 'react'
import flightPhoto from "../../Photos/slider/slider (1).jpg"

export default function Slider() {
    return (
        <div className=' w-full h-full rounded-xl overflow-hidden'>
            <img src={flightPhoto} alt="" className='  object-cover w-full h-full' />
        </div>
    )
}
