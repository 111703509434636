import { RiUserVoiceFill } from "react-icons/ri"
import FlightBookComponent from '../../Components/FlightBook/FlightBookComponent';
import SearchComponent from "../../Components/Search/SearchComponent"
import FilterComponent from "../../Components/FlightFilters/FilterComponent";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import ChipestOrFastest from "../../Components/FlightFilters/ChipestOrFastest";
import TimeoutComponent from "../../Components/FlightBook/TimeoutComponent";
import { BsMessenger } from "react-icons/bs";
import { useLocation } from "react-router-dom";
import { useState } from "react";

function FlightList() {
    const location = useLocation()
    const searchData = location.state
    const [showFilterComponent, setShowFilterComponent] = useState(false)

    return (
        <div className="w-full pt-12 lg:pt-20">
            <SearchComponent searchData={searchData} />
            <div className="block">
                <FilterComponent showFilterComponent={showFilterComponent} setShowFilterComponent={setShowFilterComponent} />
            </div>
            <div className='  w-full xl:w-[1160px] mx-auto px-5'>
                <div className="grid grid-cols-4 gap-5 my-5 mt-3">
                    <div className=' col-span-4 lg:col-span-3'>
                        <ChipestOrFastest />
                        <FlightBookComponent />
                        <FlightBookComponent />
                        <FlightBookComponent />
                        <FlightBookComponent />
                        <FlightBookComponent />
                        <FlightBookComponent />
                        <FlightBookComponent />
                        <FlightBookComponent />
                        <FlightBookComponent />
                        <FlightBookComponent />
                        <FlightBookComponent />
                        <FlightBookComponent />

                        <div className=" flex justify-center mt-5 h-10">
                            <div className=" flex items-center gap-2">
                                <div className=" w-8 bg-white rounded h-8 flex justify-center items-center border border-primary hover:bg-gray-200 cursor-pointer">
                                    <AiOutlineLeft className=" text-sm" />
                                </div>
                                <div className=" w-8 bg-primary rounded h-8 flex justify-center items-center border border-primary  cursor-pointer text-white">
                                    <span>1</span>
                                </div>
                                <div className=" w-8 bg-white rounded h-8 flex justify-center items-center border border-primary hover:bg-gray-200 cursor-pointer text-primary">
                                    <span>2</span>
                                </div>
                                <div className=" w-8 bg-white rounded h-8 flex justify-center items-center border border-primary hover:bg-gray-200 cursor-pointer text-primary">
                                    <span>2</span>
                                </div>
                                <div className=" w-8 bg-white rounded h-8 flex justify-center items-center border border-primary hover:bg-gray-200 cursor-pointer">
                                    <AiOutlineRight className=" text-sm" />
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className=" lg:col-span-1 col-span-4">
                        <div className=" w-full bg-white p-3 rounded shadow mb-5">
                            <p className=" text-primary font-semibold">Search Complete</p>
                            <p className=" text-sm text-gray-500">Showing 22 results</p>
                        </div>
                        <TimeoutComponent />
                        <div className=" bg-white shadow rounded w-full overflow-hidden mt-5">
                            <div className=" bg-primary w-full p-3">
                                <p className=" text-white font-semibold">Need Help?</p>
                            </div>
                            <div className="  w-full bg-white p-5">
                                <div className=" flex gap-3 items-center ">
                                    <div className=" h-7 w-7 flex justify-center items-center bg-yellow-400 rounded-full">
                                        <RiUserVoiceFill className=" text-sm text-gray-600" />
                                    </div>
                                    <p className=" text-primary text-sm"> +88 09678 332211</p>
                                </div>
                                <span className=" w-full h-[1px] block my-3 bg-gray-300"></span>
                                <div className=" flex gap-3 items-center ">
                                    <div className=" h-7 w-7 flex justify-center items-center bg-yellow-400 rounded-full">
                                        <BsMessenger className=" text-sm text-gray-600" />
                                    </div>
                                    <p className=" text-primary text-sm">  m.me/GoZayaanBD</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <button onClick={() => setShowFilterComponent(true)} className=" bg-yellow-400 py-1 px-2 hover:shadow-lg text-primary font-bold rounded-md fixed bottom-10 left-1/2 -translate-x-1/2 md:hidden">Flilter</button>
        </div>

    )
}

export default FlightList