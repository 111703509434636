import React from 'react'
import Carousel from 'react-multi-carousel';
import "react-multi-carousel/lib/styles.css";
import HotelTourSlideItem from './HotelTureSliderItem';

export default function HotelTourSlide({ title, option }) {


    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 2000, min: 1400 },
            items: 4
        },
        desktop: {
            breakpoint: { max: 1400, min: 1024 },
            items: 3
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };

    return (
        <div className=' w-full flex justify-center items-center'>
            <div className='w-full xl:w-[1160px] px-5 pb-20 '>
                <div className=' pb-5'>
                    <p className=' text-xl text-primary font-bold '>{title}</p>
                </div>
                <div className='gd-carousel-wrapper'>
                    <Carousel
                        arrows={true}
                        responsive={responsive}
                        className="gd-carousel"
                    >
                        <div className=' mr-5'>
                            <HotelTourSlideItem option={option} />
                        </div>
                        <div className=' mr-5'>
                            <HotelTourSlideItem option={option} />
                        </div>
                        <div className=' mr-5'>
                            <HotelTourSlideItem option={option} />
                        </div>
                        <div className=' mr-5'>
                            <HotelTourSlideItem option={option} />
                        </div>
                        <div className=' mr-5'>
                            <HotelTourSlideItem option={option} />
                        </div>
                        <div className=' mr-5'>
                            <HotelTourSlideItem option={option} />
                        </div>
                        <div className=' mr-5'>
                            <HotelTourSlideItem option={option} />
                        </div>
                    </Carousel>
                </div>
            </div>
        </div>
    )
}
