import React from 'react'
import { AiOutlineFire } from 'react-icons/ai'
import { FiArrowUpRight } from 'react-icons/fi'
import flightPhoto from "../../Photos/flight/flight (1).jpg"

export default function HotdealItem() {
    return (
        <div className=' w-full h-full p-3 lg:p-5 relative'>
            <div className=' bg-white shadow-md p-5 rounded-md '>
                <div className=' flex '>
                    <div className='w-36 h-28 rounded-md absolute -left-1 -top-1 overflow-hidden'>
                        <img src={flightPhoto} alt="" className='  object-cover w-full h-full' />
                    </div>
                    <div className='pt-20 lg:pt-0 lg:pl-28'>
                        <p className=' text-primary lg:text-lg font-bold'>Up to 15% Discount on Domestic Flights</p>
                        <p className=' text-primary text-[12px] lg:text-sm mt-2'>On base fare, for StanChart credit cards. Till 31 Dec‘22.</p>
                    </div>
                </div>
                <div className=' pt-10'>
                    <div className=' flex '>
                        <div className='bg-gray-100 p-1 rounded flex gap-1'>
                            <AiOutlineFire className=' text-lg' />
                            <span className=' lg:text-base text-sm'>SCFLY1522</span>
                        </div>
                    </div>
                </div>
                <div className=' hidden absolute bottom-2 right-8 px-3 py-2 shadow-md rounded-md bg-yellow-500 lg:flex items-center gap-2 cursor-pointer'>
                    <FiArrowUpRight />
                    <span className=' font-medium text-primary text-sm'>Learn More</span>
                </div>
            </div>
        </div>
    )
}
